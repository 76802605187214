import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'

import { loadSeries } from '../Slice/SerieSlice'

export default function SearchSerie ({ name, value, onChange, size = 7 }) {
  const dispatch = useDispatch()
  const { isLoading, list } = useSelector(state => state.serie)
  const [selectedValue, setSelectedValue] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(loadSeries({ limit: 100 }))
  }, [])

  const onDropdownChange = (e, data) => {
    if (typeof onChange === 'function') {
      onChange(e, data)
      setSelectedValue(data.value)
    }
  }

  return (
    <Form.Dropdown
      fluid
      label={t('serie.title')}
      loading={isLoading}
      name={name}
      options={list.length > 0 ? list.map((element) => ({
        key: element.id,
        text: element.name,
        value: element.id,
        description: element.name
      })) : []}
      placeholder={t('serie.search')}
      search
      selection
      value={selectedValue === null ? value : selectedValue}
      width={size}
      onChange={onDropdownChange}
    />
  )
}
