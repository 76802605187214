import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Container, Icon } from 'semantic-ui-react'
import { BsBriefcase } from 'react-icons/bs'
import { loadCompanies, removeCompany, updateState } from '../Slice/CompanySlice'
import ListTable from '../../Common/Views/ListTable'
import DeleteConfirmModal from '../../Common/Views/DeleteConfirmModal'
import './css/CompanyListPage.css'

export default function CompanyListPage () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    list,
    filter,
    totalPages,
    isLoading,
    isError,
    message
  } = useSelector((state) => state.company)
  const [deleteCompany, setDeleteCompany] = useState({})

  const columns = [
    {
      name: 'id',
      title: 'generic.id',
      filter: false,
      props: {
        textAlign: 'right',
        width: 1
      }
    },
    {
      name: 'name',
      title: 'generic.name'
    },
    {
      name: 'tag',
      title: 'generic.tag'
    },
    {
      name: 'url',
      title: 'generic.website',
      filter: false,
      sort: false
    }
  ]

  useEffect(() => {
    onReload(filter)
  }, [])

  const onReload = (payload) => {
    dispatch(loadCompanies(payload))
  }

  useEffect(() => {
    if (isError && message) {
      toast.error(message)
      dispatch(updateState())
    }
  }, [isError, message])

  const onEdit = (companyId) => {
    navigate(`/company/${companyId}`)
  }
  const onDelete = (company) => {
    setDeleteCompany(company)
  }

  const handleDeleteCompany = () => {
    dispatch(removeCompany(deleteCompany.id))
    setDeleteCompany({})
  }

  return (
    <Container className='company-list-page'>
      <div className='company-title'>
        <Icon size='large'><BsBriefcase /></Icon>
        <span>{ t('menu.companies') }</span>
        <Button
          floated='right'
          icon
          labelPosition='left'
          primary
          size='large'
          onClick={() => navigate('/company/new')}>
          <Icon name='briefcase' /> { t('company.new') }
        </Button>
      </div>
      <ListTable
        color='orange'
        isLoading={isLoading}
        columns={columns}
        filter={filter}
        data={list}
        totalPages={totalPages}
        onReload={onReload}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      <DeleteConfirmModal
        open={ !!deleteCompany.id }
        title={ t('company.deleteTitle') }
        message={ t('company.deleteQuestion') }
        item={ deleteCompany.name }
        onCancel={ () => setDeleteCompany({}) }
        onDelete={ handleDeleteCompany }
      />
    </Container>
  )
}
