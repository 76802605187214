import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { GiGamepad } from 'react-icons/gi'
import { Button, Container, Grid, Icon, Message } from 'semantic-ui-react'

import { GameForm } from '../Form/GameForm'
import { useEditHandleError } from '../../Common/Hooks/useEditHandleError'

import { loadGameById, saveGame, uploadImageToBucket, updateState, setCoverImage, setHeaderImage, setHeaderImageDeleted, setCoverImageDeleted } from '../Slice/GameSlice'
import { resetGamePlatform } from '../../GamePlatform/Slice/GamePlatformSlice'
import { deleteImage, uploadImage } from '../../Game/Service/GameService'

export default function GameEditPage () {
  const dispatch = useDispatch()
  const [errorList, goBack, handleError] = useEditHandleError('/game')
  const { gameId } = useParams()
  const {
    form: game,
    isLoading,
    isValid,
    isSuccess,
    isError,
    message
  } = useSelector((state) => state.game)
  const { t } = useTranslation()

  useEffect(() => {
    if (gameId !== 'new') {
      dispatch(resetGamePlatform(true))
      dispatch(loadGameById(gameId))
    }
  }, [])

  useEffect(() => {
    handleError(isSuccess, isError, message)
    dispatch(updateState())
  }, [isSuccess, isError, message])

  const onSaveGame = async () => {
    dispatch(uploadImageToBucket())

    if (game?.headerImageDeleted?.length > 0) {
      deleteImage(game.headerImageDeleted)
      dispatch(setHeaderImageDeleted(''))
    }

    if (game?.coverDeleted?.length > 0) {
      deleteImage(game.coverDeleted)
      dispatch(setCoverImageDeleted(''))
    }

    if (game?.headerImage && game?.originalHeaderImage && isValid) {
      const data = await uploadImage({ image: game?.headerImage, original: game?.originalHeaderImage, type: 'header' })
      if (data?.url.length) {
        dispatch(setHeaderImage(data.path))
      }
    }

    if (game?.cover && game?.originalCover && isValid) {
      const data = await uploadImage({ image: game?.cover, original: game?.originalCover, type: 'cover' })
      if (data?.url.length) {
        dispatch(setCoverImage(data.path))
      }
    }

    dispatch(saveGame())
  }

  return (
    <Container className='game-list-page'>
      <div className='game-title game-division'>
        <Grid columns={2}>
          <Grid.Column>
            <Icon size='large'>
              <GiGamepad />
            </Icon>
            <span>{t('game.new')}</span>
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <Button
              color='grey'
              disabled={isLoading}
              icon
              labelPosition='left'
              size='large'
              onClick={goBack}
            >
              <Icon name='arrow circle left' /> {t('generic.goBack')}
            </Button>
            <Button
              disabled={isLoading}
              icon
              labelPosition='left'
              color='green'
              size='large'
              onClick={onSaveGame}
            >
              <Icon name='save' /> { t('generic.save') }
            </Button>
          </Grid.Column>
        </Grid>
      </div>
      <div className='p-4'>
        <GameForm />
      </div>
      <div className='p-4'>
        { errorList.length > 0 &&
          <Message error icon='exclamation triangle'
                   header={ t('generic.validation.onSave') }
                   list={errorList} />
        }
      </div>
    </Container>
  )
}
