import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { refreshToken } from './Azure/AzureService'
import { CoreAPI } from './CoreAPI'

export function Interceptor ({ children }) {
  const { instance } = useMsal()

  useEffect(() => {
    const requestInterceptor = (config) => {
      const token = localStorage.getItem('user')
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` }
      return config
    }

    const responseInterceptor = (resp) => {
      return resp.data
    }

    const errInterceptor = (error) => {
      const { response } = error
      let data = {}

      if (response) {
        data = response.data
        if (response.status === 401) {
          if (error.config.url === '/login') {
            window.location.href = '/unauthorized'
            return {}
          }
          refreshToken(instance).then(() => {
            window.location.href = '/'
          })
        } else if (response.status === 403) {
          window.location.href = '/unauthorized'
        }
      }

      return Promise.reject(data)
    }

    const requestUse = CoreAPI.interceptors.request.use(requestInterceptor)
    const responseUse = CoreAPI.interceptors.response.use(responseInterceptor, errInterceptor)

    return () => {
      CoreAPI.interceptors.request.eject(requestUse)
      CoreAPI.interceptors.response.eject(responseUse)
    }
  }, [CoreAPI])

  return children
}
