import { Container } from 'semantic-ui-react'
import './css/NotFoundPage.css'

export default function NotFoundPage () {
  return (
    <Container className='not-found-page'>
      <h1>Ups!, algo ha fallado</h1>
      <p>Se ha producido un error no controlado.</p>
      <p>Si este es la primera vez que se produce este error, ignórelo.</p>
      <p>En caso contrario, póngase en contacto con el administrador.</p>
      <a href="/">Pulse sobre volver para ir al menu principal</a>
    </Container>
  )
}
