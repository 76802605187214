import { buildParams, CoreAPI } from '../../../Services/CoreAPI'

export const obtainPegiByFilter = async (filter) => {
  let endpoint = '/v1/pegi'

  if (filter) {
    const params = buildParams(filter)
    endpoint = `/v1/pegi?${params.toString()}`
  }

  const resp = await CoreAPI.get(endpoint)

  const {
    data: list = [],
    pagination: { totalPages = 1 } = {}
  } = resp

  if (list?.data) {
    return { list: list.data, totalPages }
  } else {
    return { list, totalPages }
  }
}

export const obtainPegiById = async (pegiId) => {
  const endpoint = `/v1/pegi/${pegiId}`
  const resp = await CoreAPI.get(endpoint)

  if (resp.data?.data) {
    return resp.data.data
  }

  return resp.data
}

export const createPegi = async (data) => {
  const endpoint = '/v1/pegi'
  const resp = await CoreAPI.post(endpoint, data)
  return resp.data
}

export const updatePegi = async (pegiId, data) => {
  const endpoint = `/v1/pegi/${pegiId}`
  const resp = await CoreAPI.put(endpoint, data)
  return resp.data
}

export const deletePegi = async (pegiId) => {
  const endpoint = `/v1/pegi/${pegiId}`
  const resp = await CoreAPI.delete(endpoint)
  return resp.data
}
