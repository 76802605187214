import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Button, Form, Icon } from 'semantic-ui-react'

import { object } from 'yup'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { useTechnicalSpecifications } from '../../Admin/Hooks/useTechnicalSpeficiations'
import { useValidateForm } from '../../Common/Hooks/useValidateForm'

import { addPlatform, deletePlatofrm, editPlatform, setPlatforms } from '../../Game/Slice/GameSlice'
import { resetGamePlatform, setGamePlatform, setGamePlatformData, sendToMetacritic, reactivateMetacritic } from '../Slice/GamePlatformSlice'

import { Checkbox } from '../../Common/Views/Checkbox'
import FormDatePicker from '../../Common/Views/DatePicker'
import SearchCompany from '../../Company/Views/SearchCompany'
import SearchPlatform from '../../Platform/Views/SearchPlatform'
import SearchTechnicalSpecifications from '../Views/SearchTechnicalSpecifications'
import SendToMetacriticModal from './Modal/SendToMetacriticModal'

export function GamePlatformForm ({ platforms, onLoadPlatform }) {
  const dispatch = useDispatch()
  const { gameId } = useParams()
  const { form: game } = useSelector((state) => state.game)
  const { form: gamePlatform, isLoading, isDirty } = useSelector((state) => state.gameplatform)
  const [openMetacriticModal, setOpenMetacriticModal] = useState(false)
  const [setValidationErrors] = useState(null)
  const { t } = useTranslation()
  const { technicalSpecifications, searchTechnicalSpeficications } = useTechnicalSpecifications()

  const gamePlatformSchema = object({
    /* score: string()
      .oneOf(['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'], 'El valor debe estar entre 1 y 10')
      .test('is-between-one-and-ten', 'El valor debe estar entre 1 y 10', value => {
        if (value === '') {
          return true
        }

        return /^[1-9]|10$/.test(value)
      }) */
  })

  const [errors, validate, handleChange, handleDateChange] = useValidateForm(gamePlatform, gamePlatformSchema)

  useEffect(() => {
    validate(gamePlatform)
  }, [isDirty])

  useEffect(() => {
    searchTechnicalSpeficications()
  }, [])

  const updateFormData = (data, isValid) => {
    dispatch(setGamePlatformData({ data, isValid }))
  }

  const onDeletePlatform = () => {
    dispatch(deletePlatofrm(gamePlatform?.platformId))
  }

  const onSavePlatform = () => {
    const isGamePlatformAdded = game.platforms?.find((platform) => platform.platformId === gamePlatform.platformId)

    gamePlatformSchema.validate(gamePlatform, { abortEarly: false })
      .then(() => {
        if (gameId === 'new') {
          if (!isGamePlatformAdded) {
            const gamePlatformToAdd = {
              ...gamePlatform,
              id: game?.platforms.length + 1
            }
            dispatch(addPlatform([...game?.platforms, gamePlatformToAdd]))
          } else {
            dispatch(editPlatform(gamePlatform))
          }

          dispatch(resetGamePlatform(true))
        } else {
          const lastPlatform = game.platforms[game.platforms.length - 1]
          if (game?.platforms.length === 0 || !isGamePlatformAdded) {
            dispatch(addPlatform([
              ...game.platforms,
              {
                ...gamePlatform,
                name: platforms.find((platform) => platform.value === gamePlatform.platformId)?.text,
                id: lastPlatform ? lastPlatform?.id + 1 : 1
              }
            ]))
          } else {
            dispatch(editPlatform(gamePlatform))
          }
        }
      })
      .catch((err) => {
        const errors = {}
        err.inner.forEach((e) => errors[e.path] = e.message)
        setValidationErrors(errors)
      })
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const updatedPlatforms = Array.from(game.platforms)
    const [movedPlatform] = updatedPlatforms.splice(source.index, 1)
    updatedPlatforms.splice(destination.index, 0, movedPlatform)

    const platformsOrdered = updatedPlatforms.map((platform, index) => {
      return {
        ...platform,
        weight: index + 1
      }
    })

    dispatch(setGamePlatform(movedPlatform))
    dispatch(setPlatforms(platformsOrdered))
  }

  const onSendToMetacritic = () => {
    dispatch(sendToMetacritic(true))
  }

  return (
    <Form autoComplete='off' loading={isLoading}>
      <Form.Group widths='equal'>
        <SearchPlatform
          name='platformId'
          value={gamePlatform.platformId}
          onChange={handleChange(updateFormData)}
        />
      </Form.Group>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='table' direction='horizontal'>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {
                game?.platforms.map((gamePlatform, index) => (
                  <Draggable key={gamePlatform.id} draggableId={gamePlatform.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        onClick={() => onLoadPlatform(gamePlatform.platformId)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          background: snapshot.isDragging ? '#263B4A' : '#456C86',
                          color: 'white',
                          fontSize: 16,
                          margin: '0 2px',
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 16,
                          paddingRight: 16,
                          userSelect: 'none',
                          ...provided.draggableProps.style
                        }}
                      >
                        {
                          gameId === 'new'
                            ? platforms.find((platform) => platform.value === gamePlatform.platformId)?.text
                            : gamePlatform.name
                        }
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {
        gamePlatform?.platformId !== null || (game.platforms.length > 0 && gameId !== 'new')
          ? <div style={{ border: '1px solid black', padding: '1.5rem' }}>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: gamePlatform?.platformId ? 'space-between' : 'flex-end' }}>
              {
                gamePlatform?.platformId &&
                  <p style={{ fontSize: 24, fontWeight: 700 }}>
                    <p>{t('platform.title')}: { platforms.find((platform) => platform.value === gamePlatform.platformId)?.text } </p>
                  </p>
              }
              <div>
                {
                  gameId !== 'new' && gamePlatform.metaCriticSent &&
                    <Button
                      icon
                      labelPosition='left'
                      primary
                      type='button'
                      onClick={() => dispatch(reactivateMetacritic(true))}
                    >
                      <Icon name='send' /> {t('gamePlatform.reactivateMetacriticShipment')}
                    </Button>
                }
                {
                  gameId !== 'new' && !gamePlatform.metaCriticSent &&
                    <Button
                      disabled={
                        !gamePlatform.metaCriticText ||
                        gamePlatform.metaCriticText.length === 0 ||
                        !game?.tag ||
                        game?.tag.length === 0
                      }
                      icon
                      labelPosition='left'
                      primary
                      type='button'
                      onClick={() => setOpenMetacriticModal(true)}
                    >
                      <Icon name='send' /> {t('gamePlatform.manualSentToMetacritic')}
                  </Button>
                }
                <Button
                  color='green'
                  icon
                  labelPosition='left'
                  onClick={onSavePlatform}
                >
                  <Icon name='save' /> { t('generic.save') }
                </Button>
                <Button
                  color='red'
                  icon
                  labelPosition='left'
                  type='button'
                  onClick={onDeletePlatform}
                >
                  <Icon name='trash' /> { t('generic.delete') }
                </Button>
              </div>
            </div>
            <Form.Input
              label={`${t('gamePlatform.score')} ${t('generic.withQuote')}`}
              name='score'
              placeholder={t('gamePlatform.score')}
              type='number'
              value={gamePlatform.score}
              onChange={handleChange(updateFormData)}
            />
            <div className='mb-1'>
              <Checkbox
                isToggle
                label={t('gamePlatform.scoreInProgress')}
                name='scoreOnProgress'
                value={gamePlatform.scoreOnProgress}
                onChange={handleChange(updateFormData)}
              />
            </div>
            <Form.Field
              className='no-resize'
              control='textarea'
              disabled={gamePlatform.metaCriticSent}
              error={isDirty && errors.metaCriticText}
              label={t('gamePlatform.metacriticText')}
              name='metaCriticText'
              rows='5'
              value={gamePlatform.metaCriticText}
              onChange={handleChange(updateFormData)}
            />
            {
              gameId !== 'new' &&
                <Checkbox
                  disabled
                  isToggle
                  label={t('gamePlatform.sentToMetacritic')}
                  name='metaCriticSent'
                  value={gamePlatform.metaCriticSent}
                  onChange={handleChange(updateFormData)}
                />
            }
            <Form.Group widths='equal'>
              <FormDatePicker
                label={`${t('gamePlatform.releaseDate')} (ES)`}
                name='launchDateES'
                value={gamePlatform.launchDateES === 0 ? null : gamePlatform.launchDateES}
                onChange={handleDateChange(updateFormData)}
              />
              <FormDatePicker
                label={`${t('gamePlatform.releaseDate')} (US)`}
                name='launchDateUS'
                value={gamePlatform.launchDateUS === 0 ? null : gamePlatform.launchDateUS}
                onChange={handleDateChange(updateFormData)}
              />
              <FormDatePicker
                label={`${t('gamePlatform.releaseDate')} (JP)`}
                name='launchDateJP'
                value={gamePlatform.launchDateJP === 0 ? null : gamePlatform.launchDateJP}
                onChange={handleDateChange(updateFormData)}
                />
            </Form.Group>
            <Form.Group widths='equal'>
              <SearchCompany
                label={t('developerCompany')}
                name='devCompanyId'
                value={gamePlatform?.devCompanyId}
                onChange={handleChange(updateFormData)}
              />
              <SearchCompany
                label={t('publishingCompany')}
                name='editorCompanyId'
                value={gamePlatform?.editorCompanyId}
                onChange={handleChange(updateFormData)}
              />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input
                  label={t('gamePlatform.maximumOnlinePlayers')}
                  name='maxPlayersOnline'
                  placeholder={t('gamePlatform.maximumOnlinePlayers')}
                  value={gamePlatform.maxPlayersOnline ? gamePlatform.maxPlayersOnline : null}
                  onChange={handleChange(updateFormData)}
                />
                <Form.Input
                  label={t('gamePlatform.maximumOfflinePlayers')}
                  name='maxPlayersOffline'
                  placeholder={t('gamePlatform.maximumOfflinePlayers')}
                  value={gamePlatform.maxPlayersOffline ? gamePlatform.maxPlayersOffline : null}
                  onChange={handleChange(updateFormData)}
                />
              </Form.Group>
              <Form.Field
                className='no-resize'
                control='textarea'
                label={t('gamePlatform.minimumRequirements')}
                name='minimumSystem'
                rows='5'
                value={gamePlatform.minimumSystem}
                onChange={handleChange(updateFormData)}
              />
              <Form.Field
                className='no-resize'
                control='textarea'
                label={t('gamePlatform.recommendedRequirements')}
                name='recommendedSystem'
                rows='5'
                value={gamePlatform.recommendedSystem}
                onChange={handleChange(updateFormData)}
              />
              <Form.Group widths='equal'>
                <Form.Input
                  label={t('gamePlatform.estimatedDuration')}
                  name='estimatedDuration'
                  placeholder={t('gamePlatform.estimatedDuration')}
                  value={gamePlatform.estimatedDuration ? gamePlatform.estimatedDuration : null}
                  onChange={handleChange(updateFormData)}
                />
                <Form.Input
                  label={t('gamePlatform.saveGameSize')}
                  name='saveGameUnits'
                  placeholder={t('gamePlatform.saveGameSize')}
                  value={gamePlatform.saveGameUnits}
                  onChange={handleChange(updateFormData)}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <SearchTechnicalSpecifications
                  label='voices'
                  name='voicesLanguage'
                  options={technicalSpecifications}
                  value={gamePlatform?.voicesLanguage}
                  placeholder={t('gamePlatform.searchVoices')}
                  onChange={handleChange(updateFormData)}
                />
                <SearchTechnicalSpecifications
                  label='texts'
                  name='textLanguage'
                  options={technicalSpecifications}
                  value={gamePlatform?.textLanguage}
                  placeholder={t('gamePlatform.searchTexts')}
                  onChange={handleChange(updateFormData)}
                />
                <SearchTechnicalSpecifications
                  label='manualLanguage'
                  name='manualLanguage'
                  options={technicalSpecifications}
                  value={gamePlatform?.manualLanguage}
                  placeholder={t('gamePlatform.searchManualLanguages')}
                  onChange={handleChange(updateFormData)}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <SearchTechnicalSpecifications
                  label='videoModes'
                  name='videoModes'
                  options={technicalSpecifications}
                  value={gamePlatform?.videoModes}
                  placeholder={t('gamePlatform.searchVideoModes')}
                  onChange={handleChange(updateFormData)}
                />
                <SearchTechnicalSpecifications
                  label='APIdrivers'
                  name='driverApis'
                  options={technicalSpecifications}
                  value={gamePlatform?.driverApis}
                  placeholder={t('gamePlatform.searchApiDrivers')}
                  onChange={handleChange(updateFormData)}
                />
                <SearchTechnicalSpecifications
                  label='sound'
                  name='soundsStandards'
                  options={technicalSpecifications}
                  value={gamePlatform?.soundsStandards}
                  placeholder={t('gamePlatform.searchSounds')}
                  onChange={handleChange(updateFormData)}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <SearchTechnicalSpecifications
                  label='multiplayerSystems'
                  name='multiplayer'
                  options={technicalSpecifications}
                  value={gamePlatform?.multiplayer}
                  placeholder={t('gamePlatform.searchMultiplayerSystems')}
                  onChange={handleChange(updateFormData)}
                />
                <SearchTechnicalSpecifications
                  label='multiplayerModes'
                  name='multiplayerModes'
                  options={technicalSpecifications}
                  value={gamePlatform?.multiplayerModes}
                  placeholder={t('gamePlatform.searchMultiplayerModes')}
                  onChange={handleChange(updateFormData)}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <SearchTechnicalSpecifications
                  label='storageMedias'
                  name='storageMedia'
                  options={technicalSpecifications}
                  value={gamePlatform?.storageMedia}
                  placeholder={t('gamePlatform.searchStorageMedias')}
                  onChange={handleChange(updateFormData)}
                />
                <SearchTechnicalSpecifications
                  label='inputDevices'
                  name='inputDevices'
                  options={technicalSpecifications}
                  value={gamePlatform?.inputDevices}
                  placeholder={t('gamePlatform.searchInputDevices')}
                  onChange={handleChange(updateFormData)}
                />
                <SearchTechnicalSpecifications
                  label='otherAttributes'
                  name='misc'
                  options={technicalSpecifications}
                  value={gamePlatform?.misc}
                  placeholder={t('gamePlatform.searchOtherAttributes')}
                  onChange={handleChange(updateFormData)}
                />
              </Form.Group>
          </div>
          : null
        }
        <SendToMetacriticModal
          message={t('gamePlatform.checkMetacriticMessage')}
          metacriticMessage={`${gamePlatform?.metaCriticText}`}
          open={openMetacriticModal}
          title={t('gamePlatform.manualSentToMetacritic')}
          onCancel={() => setOpenMetacriticModal(false)}
          onSend={onSendToMetacritic}
        />
    </Form>
  )
}
