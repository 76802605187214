import { ES, GB } from 'country-flag-icons/react/3x2'
import { useTranslation } from 'react-i18next'
import './css/SwitchLanguage.css'
import { useEffect } from 'react'

const VALID_LANG = ['es', 'en']

export default function SwitchLanguage () {
  const { t, i18n } = useTranslation()

  let gbClass = 'icon-lang'
  let esClass = 'icon-lang'

  if (i18n.language === 'en') {
    gbClass += ' active'
  } else {
    esClass += ' active'
  }

  const changeLanguage = (lang) => {
    if (!lang || VALID_LANG.indexOf(lang) === -1) {
      return
    }
    i18n.changeLanguage(lang).then()
    localStorage.setItem('language', lang)
  }

  useEffect(() => {
    const lang = localStorage.getItem('language')
    changeLanguage(lang)
  }, [])

  return (
    <div className='lang-selector'>
      <GB height='25px'
          title={t('lang.english')}
          className={gbClass}
          onClick={() => changeLanguage('en')} />
      <ES height='25px'
          title={t('lang.spanish')}
          className={esClass}
          onClick={() => changeLanguage('es')} />
    </div>
  )
}
