import { createSlice } from '@reduxjs/toolkit'

import { makeInitialState, newResource, saveResource, setResourceData } from '../../Common/Slice/ResourceSlice'

const getGamePlatformFilters = () => ({
  name: ''
})

export const getGamePlatformForm = () => ({
  platformId: null,
  weight: 1,
  devCompanyId: null,
  editorCompanyId: null,
  cover: '',
  score: '',
  scoreOnProgress: false,
  launchDateES: null,
  launchDateUS: null,
  launchDateJP: null,
  metaCriticText: '',
  metaCriticSent: false,
  maxPlayersOnline: null,
  maxPlayersOffline: null,
  estimatedDuration: null,
  saveGameUnits: null,
  minimumSystem: '',
  recommendedSystem: '',
  isCancelled: false,
  is3DRequired: false,
  voicesLanguage: [],
  textLanguage: [],
  manualLanguage: [],
  gameModes: [],
  videoModes: [],
  driverApis: [],
  soundsStandards: [],
  multiplayer: [],
  multiplayerModes: [],
  storageMedia: [],
  inputDevices: [],
  misc: []
})

export const gamePlatformSlice = createSlice({
  name: 'gameplatform',
  initialState: makeInitialState(getGamePlatformFilters(), getGamePlatformForm()),
  reducers: {
    newGamePlatform: (state) => {
      newResource(state, getGamePlatformForm())
    },
    setGamePlatformData: (state, action) => {
      setResourceData(state, action)
    },
    saveGamePlatform: (state) => {
      saveResource(state)
    },
    setGamePlatform: (state, action) => {
      state.form = action.payload
    },
    resetGamePlatform: (state, action) => {
      state.form.platformId = action.payload !== true ? action.payload : null
      state.form.weight = 1
      state.form.devCompanyId = null
      state.form.editorCompanyId = null
      state.form.cover = ''
      state.form.score = ''
      state.form.scoreOnProgress = false
      state.form.launchDateES = null
      state.form.launchDateUS = null
      state.form.launchDateJP = null
      state.form.metaCriticText = ''
      state.form.metaCriticSent = false
      state.form.maxPlayersOnline = null
      state.form.maxPlayersOffline = null
      state.form.estimatedDuration = null
      state.form.saveGameUnits = ''
      state.form.minimumSystem = ''
      state.form.recommendedSystem = ''
      state.form.isCancelled = false
      state.form.is3DRequired = false
      state.form.voicesLanguage = []
      state.form.textLanguage = []
      state.form.manualLanguage = []
      state.form.gameModes = []
      state.form.videoModes = []
      state.form.driverApis = []
      state.form.soundsStandards = []
      state.form.multiplayer = []
      state.form.multiplayerModes = []
      state.form.storageMedia = []
      state.form.inputDevices = []
      state.form.misc = []
    },
    sendToMetacritic: (state, action) => {},
    reactivateMetacritic: (state, action) => {},
    setMetacriticSent: (state, action) => {
      state.form.metaCriticSent = action.payload
    }
  }
})

export const {
  newGamePlatform,
  setGamePlatformData,
  setGamePlatform,
  sendToMetacritic,
  saveGamePlatform,
  setMetacriticSent,
  resetGamePlatform,
  reactivateMetacritic
} = gamePlatformSlice.actions

export default gamePlatformSlice.reducer
