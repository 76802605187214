import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'

import { useTechnicalSpecifications } from '../Hooks/useTechnicalSpeficiations'

export default function SearchTechnicalSpecificationTypes ({ name = 'technical-specification', value, onChange, size = 7 }) {
  const { t } = useTranslation()

  const { technicalSpecificationsMapped, searchTechnicalSpeficications } = useTechnicalSpecifications()

  useEffect(() => {
    searchTechnicalSpeficications()
  }, [])

  const onDropdownChange = (e, data) => {
    if (typeof onChange === 'function') {
      onChange(e, data)
    }
  }

  return (
    <Form.Dropdown
      fluid
      name={name}
      options={technicalSpecificationsMapped}
      placeholder={t('admin.searchTechnicalSpecifications')}
      selection
      value={value}
      width={size}
      onChange={onDropdownChange}
    />
  )
}
