import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Divider, Form, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react'

import { useTechnicalSpecifications } from '../Hooks/useTechnicalSpeficiations'
import { updateTechnicalSpecification } from '../Service/TechnicalSpecificationService'

import SearchTechnicalSpecificationTypes from './SearchTechnicalSpecificationTypes'

export default function AdminTechnicalSpecifications () {
  const [technicalSpecification, setTechnicalSpecification] = useState(null)
  const [specification, setSpecification] = useState('')
  const [specifications, setSpecifications] = useState([])
  const { technicalSpecifications, searchTechnicalSpeficications } = useTechnicalSpecifications()
  const { t } = useTranslation()

  useEffect(() => {
    searchTechnicalSpeficications()
  }, [])

  const onChange = (e, data) => {
    if (data.name === 'specification') {
      setSpecification(data.value)
    } else {
      setSpecifications([])
      setTechnicalSpecification(data.value)
    }
  }

  const onAddSpecification = () => {
    setSpecifications(prevState => [...prevState, specification])
  }

  const onSave = () => {
    const prevSpecifications = technicalSpecifications
      .filter(specification => specification.type === technicalSpecification)
      .map(specification => specification.values)
    const allSpecifications = [...prevSpecifications, specifications].flat()
    updateTechnicalSpecification(technicalSpecification, allSpecifications)

    setSpecifications([])
    setSpecification(null)
    searchTechnicalSpeficications()
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='admin-title'>
          <span>{ t('admin.technicalSpecifications') }</span>
        </div>
        <Button
          color='green'
          icon
          labelPosition='left'
          onClick={onSave}
        >
          <Icon name='save' /> {t('generic.save')}
        </Button>
      </div>
      <Divider />
      <SearchTechnicalSpecificationTypes
        onChange={onChange}
      />
      {
        technicalSpecification !== null &&
          <div style={{ marginTop: '1rem' }}>
            <Form.Input
              fluid
              label={'Especificación técnica'}
              name='specification'
              placeholder={'Añadir especificación'}
              value={specification}
              onChange={onChange}
            />
            <Button
              color='green'
              icon
              labelPosition='left'
              onClick={onAddSpecification}
              style={{ marginTop: '0.5rem' }}
              type='submit'
            >
              <Icon name='add' /> {t('admin.addTechnicalSpecification')}
            </Button>
        </div>
      }
      {
        specifications.length > 0 &&
          <div style={{ marginTop: '1rem' }}>
            <h5>Especificaciones añadidas (Pulsa Guardar para confirmar los cambios)</h5>
            {
              specifications.map(specification => (
                <p key={specification}>{specification}</p>
              ))
            }
          </div>
      }
      <Table celled>
        {
          technicalSpecifications.length > 0 &&
            technicalSpecifications
              .filter(specification => specification.type === technicalSpecification)
              .map(specification => (
                <>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Nombre</TableHeaderCell>
                      <TableHeaderCell>Operaciones</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {
                      specification.values.map(specification => (
                        <TableRow key={specification}>
                          <TableCell>{specification}</TableCell>
                          <TableCell>
                            <Button
                              color='red'
                              icon
                              labelPosition='left'
                              onClick={() => {}}
                            >
                              <Icon name='trash' /> {t('admin.deleteTechnicalSpecification')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </>
              ))
          }
        </Table>
    </>
  )
}
