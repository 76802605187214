import { useState } from 'react'

export const useValidateForm = (formValue, schema) => {
  const errorsInitialValue = {}
  Object.keys(schema.fields).forEach((key) => {
    errorsInitialValue[key] = false
  })
  const [errors, setErrors] = useState(errorsInitialValue)

  const validate = (payload, callback = null) => {
    const currentErrors = {}
    Object.keys(errors).forEach((key) => {
      currentErrors[key] = false
    })

    let isValid
    schema.validate(payload, { abortEarly: false })
      .then(() => {
        isValid = true
      })
      .catch((validation) => {
        isValid = false
        validation.inner.forEach((err) => {
          currentErrors[err.path] = {
            content: err.message
          }
        })
      })
      .finally(() => {
        setErrors(currentErrors)
        if (typeof callback === 'function') {
          callback(payload, isValid)
        }
      })
  }

  const handleChange = (callback) => {
    return (e, data) => {
      const payload = { ...formValue }
      if (undefined === data) {
        data = e.target
      }

      const { name, value } = data

      if (data.type === 'checkbox') {
        payload[name] = data.checked
      } else {
        if (name) {
          payload[name] = value
        }
      }

      validate(payload, callback)
    }
  }

  const handleDateChange = (callback) => {
    return (name, data) => {
      const payload = { ...formValue }

      if (undefined === data) {
        data = Date.now()
      }

      payload[name] = Number(new Date(data).getTime().toString().substring(0, 10))

      validate(payload, callback)
    }
  }

  return [errors, validate, handleChange, handleDateChange]
}
