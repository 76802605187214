import { Container, Divider, Grid, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getMenuData } from '../Layout/helpers/MenuHelper'
import { SmallBox } from '../Features/Common/Views/SmallBox'
import './css/HomePage.css'

export default function HomePage () {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const items = getMenuData(true)

  const handleSelectOption = (item) => {
    navigate(item.path)
  }

  return (
    <Container className='home-page'>
      <div className='home-title'>{ t('home.welcome') }</div>
      <Divider />
      <Grid columns={10} style={{ display: 'flex', justifyContent: 'center' }}>
        { items.map((item) => (
          <Grid.Column key={item.name} computer={5} mobile={16} tablet={8}
                       style={{ display: 'flex', justifyContent: 'center' }}>
            <SmallBox
              title={ t(item.name) }
              description={ t(item.name) }
              color={ item.color }
              onClick={() => handleSelectOption(item)}>
              <Icon size='massive'>{ item.icon }</Icon>
            </SmallBox>
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  )
}
