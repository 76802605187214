import React, { useEffect, useState } from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default function RichTextEditor ({ data, onChange }) {
  const [editor, setEditor] = useState(null)
  const [refreshed, setRefreshed] = useState(false)

  const config = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'undo',
      'redo',
      '|'
    ]
  }

  useEffect(() => {
    if (editor && !refreshed) {
      editor.setData(data)
      setRefreshed(true)
    }
  }, [data, editor])

  return (
    <div>
      <p style={{ fontSize: '.92em', fontWeight: 700, margin: '0 0 .28rem 0' }}>Descripción</p>
      <CKEditor
        config={config}
        data={data}
        editor={!editor ? ClassicEditor : editor}
        onBlur={(_, editor) => onChange(editor.getData())}
        onReady={(e) => setEditor(e)}
      />
    </div>

  )
}
