import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import commonEs from './es/common.json'
import commonEn from './en/common.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'es',
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false
    },
    resources: {
      es: {
        translation: commonEs
      },
      en: {
        translation: commonEn
      }
    }
  })

export default i18n
