import { useState } from 'react'
import { obtainTechnicalSpecificationByFilter } from '../Service/TechnicalSpecificationService'

const ORDERED_SPECIFICATIONS = {
  voicesLanguage: 'Lenguajes de las Voces',
  textLanguage: 'Lenguajes de los Textos',
  manualLanguage: 'Lenguajes del Manual',
  gameModes: 'Modos de Juego',
  videoModes: 'Modos de Video',
  driverApis: 'Driver APIS',
  soundsStandards: 'Tipos de Sonido',
  multiplayer: 'Multijugador',
  multiplayerModes: 'Modos Multijugador',
  storageMedia: 'Medios de Almacenamiento',
  inputDevices: 'Dispositivos de Entrada',
  misc: 'Miscelanea'
}

export const useTechnicalSpecifications = () => {
  const [technicalSpecificationsMapped, setTechnicalSpecificationsMapped] = useState([])
  const [technicalSpecifications, setTechnicalSpecifications] = useState([])

  const searchTechnicalSpeficications = () => {
    obtainTechnicalSpecificationByFilter()
      .then((response) => {
        let data = []

        if (response?.list.length) {
          const { list } = response
          data = list
        } else {
          const { data: list } = response?.list
          data = list
        }

        const list = data.map((element) => ({
          key: element.type,
          text: ORDERED_SPECIFICATIONS[element.type],
          value: element.type,
          description: element.type
        }))

        setTechnicalSpecificationsMapped(list)
        setTechnicalSpecifications(data)
      })
      .catch(() => {
        setTechnicalSpecifications([])
        setTechnicalSpecificationsMapped([])
      })
  }

  return {
    technicalSpecifications,
    technicalSpecificationsMapped,
    searchTechnicalSpeficications
  }
}
