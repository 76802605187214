import { buildParams, CoreAPI } from '../../../Services/CoreAPI'

import { formatToResponseJson } from '../Helpers/index'

export const obtainGuideByFilter = async (filter) => {
  const params = buildParams(filter)
  const endpoint = `/v1/guide?${params.toString()}`

  const resp = await CoreAPI.get(endpoint)

  const {
    data: list = [],
    pagination: { totalPages = 1 } = {}
  } = resp

  if (list?.data) {
    return { list: list.data, totalPages }
  } else {
    return { list, totalPages }
  }
}

export const obtainGuideById = async (guideId) => {
  const endpoint = `/v1/guide/${guideId}`
  const resp = await CoreAPI.get(endpoint)

  let data = []

  if (resp.data?.data) {
    data = formatToResponseJson(resp.data.data)
  }

  data = formatToResponseJson(resp.data)
  return data
}

export const createGuide = async (data) => {
  const endpoint = '/v1/guide'
  const resp = await CoreAPI.post(endpoint, data)
  return resp.data
}

export const updateGuide = async (guideId, data) => {
  const endpoint = `/v1/guide/${guideId}`
  const resp = await CoreAPI.put(endpoint, data)
  return resp.data
}

export const deleteGuide = async (guideId) => {
  const endpoint = `/v1/guide/${guideId}`
  const resp = await CoreAPI.delete(endpoint)
  return resp.data
}
