import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'

import { loadPegiList } from '../Slice/PegiSlice'

export default function SearchPegiCategories ({ name = 'pegies', value, onChange, size = 7 }) {
  const dispatch = useDispatch()
  const { isLoading, list } = useSelector(state => state.pegi)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(loadPegiList({ limit: 100 }))
  }, [])

  const onDropdownChange = (e, data) => {
    if (typeof onChange === 'function') {
      onChange(e, data)
    }
  }

  const handleSearchPegiCategories = (ev, { searchQuery }) => {
    if (searchQuery.length === 0) {
      dispatch(loadPegiList())
    }

    if (searchQuery.length >= 3) {
      const payload = { name: searchQuery }
      dispatch(loadPegiList(payload))
    }
  }

  return (
    <Form.Dropdown
      fluid
      icon='search'
      label={t('pegi.categories')}
      loading={isLoading}
      multiple
      name={name}
      options={list.length > 0 ? list
        .filter((element) => !element.name.match(/\+[0-9]+/))
        .map((element) => ({
          key: element.id,
          text: element.name,
          value: element.id,
          description: element.name
        })) : []}
      placeholder={t('pegi.search')}
      search={(val) => val}
      selection
      value={value}
      width={size}
      onChange={onDropdownChange}
      onSearchChange={handleSearchPegiCategories}
    />
  )
}
