import { createSlice } from '@reduxjs/toolkit'

const ADMIN_GROUP = 'global'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    token: '',
    user: {
      name: '',
      displayName: '',
      groups: []
    },
    isAdmin: false
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setUser: (state, action) => {
      const {
        name = '',
        displayName = '',
        groups = []
      } = action.payload

      state.user = {
        name,
        displayName,
        groups
      }
      state.isAdmin = groups.indexOf(ADMIN_GROUP) !== -1
    }
  }
})

export const { setToken, setUser } = userSlice.actions

export default userSlice.reducer
