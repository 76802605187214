import { useState } from 'react'

import { obtainPlatformsByFilter } from '../Service/PlatformService'

export default function usePlatforms () {
  const [isFetching, setIsFetching] = useState(false)
  const [platforms, setPlatforms] = useState([])

  const searchPlatforms = () => {
    setIsFetching(true)
    obtainPlatformsByFilter({ limit: 100 })
      .then((response) => {
        let data = []
        if (response?.list?.length) {
          const { list } = response
          data = list
        } else {
          const { data: list } = response?.list
          data = list
        }

        const list = data.map((element) => ({
          key: element.id,
          text: element.name,
          value: element.id,
          description: element.name
        }))

        setPlatforms(list)
        setIsFetching(false)
      })
      .catch(() => {
        setPlatforms([])
        setIsFetching(false)
      })
  }

  return {
    isFetching,
    platforms,
    searchPlatforms
  }
}
