import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadGenres,
  loadGenreById,
  setGenres,
  setGenreData,
  saveGenre,
  removeGenre,
  updateState
} from '../Slice/GenreSlice'
import {
  obtainGenreByFilter,
  obtainGenreById,
  createGenre,
  updateGenre,
  deleteGenre
} from '../Service/GenresService'

function * fetchGenres () {
  try {
    const { filter } = yield select(state => state.genre)
    const data = yield call(obtainGenreByFilter, filter)
    yield put(setGenres(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchGenreById () {
  try {
    const { keyId } = yield select((state) => state.genre)
    const data = yield call(obtainGenreById, keyId)
    yield put(setGenreData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storeGenre () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.genre)
    if (isValid) {
      if (keyId > 0) {
        yield call(updateGenre, keyId, form)
      } else {
        yield call(createGenre, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * eraseGenre (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const genreId = action.payload
    yield call(deleteGenre, genreId)

    // reload list
    const { filter } = yield select(state => state.genre)
    const data = yield call(obtainGenreByFilter, filter)
    yield put(setGenres(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * genreSaga () {
  yield takeEvery(loadGenres, fetchGenres)
  yield takeEvery(loadGenreById, fetchGenreById)
  yield takeEvery(saveGenre, storeGenre)
  yield takeEvery(removeGenre, eraseGenre)
}

export default genreSaga
