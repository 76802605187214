import './css/GuideListPage.css'

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BiEdit } from 'react-icons/bi'
import { Button, Container, Grid, Icon, Message } from 'semantic-ui-react'
import { useEditHandleError } from '../../Common/Hooks/useEditHandleError'

import GuideForm from '../Form/GuideForm'
import { useDispatch, useSelector } from 'react-redux'
import { loadGuideById, newGuide, saveGuide, updateState } from '../Slice/GuideSlice'

export default function GuideEditPage () {
  const dispatch = useDispatch()
  const { guideId } = useParams()
  const { t } = useTranslation()

  const {
    isLoading,
    isSuccess,
    isError,
    message
  } = useSelector((state) => state.guide)
  const [errorList, goBack, handleError] = useEditHandleError('/guide')

  useEffect(() => {
    if (guideId !== 'new') {
      dispatch(loadGuideById(guideId))
    } else {
      dispatch(newGuide())
    }
  }, [])

  useEffect(() => {
    handleError(isSuccess, isError, message)
    dispatch(updateState())
  }, [isSuccess, isError, message])

  const onSaveGuide = () => {
    dispatch(saveGuide())
  }

  return (
    <Container className='guide-list-page'>
      <div className='guide-title guide-division'>
        <Grid columns={2}>
          <Grid.Column>
            <Icon size='large'><BiEdit /></Icon>
            {
              guideId === 'new'
                ? <span>{t('guide.new')}</span>
                : <span>Editar Guía #{guideId}</span>
            }
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <Button
              color='grey'
              disabled={isLoading}
              icon
              labelPosition='left'
              size='large'
              onClick={goBack}
            >
              <Icon name='arrow circle left' /> {t('generic.goBack')}
            </Button>
            <Button
              color='green'
              disabled={isLoading}
              icon
              labelPosition='left'
              size='large'
              onClick={onSaveGuide}
            >
              <Icon name='save' /> {t('generic.save')}
            </Button>
          </Grid.Column>
        </Grid>
      </div>
      <div className='p-4'>
        <GuideForm />
      </div>
      <div className='p-4'>
        {
          errorList.length > 0 && <Message
            error icon='exclamation triangle'
            header={t('generic.validation.onSave')}
            list={errorList}
          />
        }
      </div>
    </Container>
  )
}
