import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getEsrbFilters = () => ({
  name: ''
})

const getEsrbForm = () => ({
  name: ''
})

export const esrbSlice = createSlice({
  name: 'esrb',
  initialState: makeInitialState(getEsrbFilters(), getEsrbForm()),
  reducers: {
    loadEsrbList: (state, action) => {
      loadResourceList(state, action, getEsrbFilters())
    },
    setEsrbList: (state, action) => {
      setResourceList(state, action)
    },
    newEsrb: (state) => {
      newResource(state, getEsrbForm())
    },
    loadEsrbById: (state, action) => {
      loadResourceById(state, action, getEsrbForm())
    },
    setEsrbData: (state, action) => {
      setResourceData(state, action)
    },
    saveEsrb: (state) => {
      saveResource(state)
    },
    removeEsrb: (state) => {
      removeResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    }
  }
})

export const {
  loadEsrbList,
  setEsrbList,
  newEsrb,
  loadEsrbById,
  setEsrbData,
  saveEsrb,
  removeEsrb,
  updateState
} = esrbSlice.actions

export default esrbSlice.reducer
