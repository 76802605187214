import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'

export default function SearchTechnicalSpecifications ({
  label = '',
  name = 'technical-specifications',
  options = [],
  size = 7,
  value = [],
  placeholder,
  onChange
}) {
  const { t } = useTranslation()

  const specificationsFiltered = options
    .filter(option => option.type === name)
    .map(specification => specification.values)
    .flat()

  const onDropdownChange = (e, data) => {
    if (typeof onChange === 'function') {
      onChange(e, data)
    }
  }

  return (
    <Form.Dropdown
      fluid
      label={t(`gamePlatform.${label}`)}
      multiple
      name={name}
      options={specificationsFiltered
        .map(specification => ({
          key: specification,
          text: specification,
          value: specification,
          description: specification
        }))
      }
      placeholder={placeholder}
      search
      selection
      value={value}
      width={size}
      onChange={onDropdownChange}
    />
  )
}
