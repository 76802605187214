import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
// reducers
import userReducer from '../Features/User/Slice/UserSlice'
import companyReducer from '../Features/Company/Slice/CompanySlice'
import esrbReducer from '../Features/Esrb/Slice/EsrbSlice'
import gameReducer from '../Features/Game/Slice/GameSlice'
import gamePlatformReducer from '../Features/GamePlatform/Slice/GamePlatformSlice'
import genreReducer from '../Features/Genre/Slice/GenreSlice'
import guideReducer from '../Features/Guide/Slice/GuideSlice'
import pegiReducer from '../Features/Pegi/Slice/PegiSlice'
import platformReducer from '../Features/Platform/Slice/PlatformSlice'
import serieReducer from '../Features/Serie/Slice/SerieSlice'
import subgenreReducer from '../Features/Subgenre/Slice/SubgenreSlice'
// sagas
import userSaga from '../Features/User/Sagas/UserSaga'
import companySaga from '../Features/Company/Sagas/CompanySaga'
import esrbSaga from '../Features/Esrb/Sagas/EsrbSaga'
import gameSaga from '../Features/Game/Sagas/GameSaga'
import gamePlatformSaga from '../Features/GamePlatform/Sagas/GamePlatformSaga'
import genreSaga from '../Features/Genre/Sagas/GenreSaga'
import guideSaga from '../Features/Guide/Sagas/GuideSaga'
import platformSaga from '../Features/Platform/Sagas/PlatformSaga'
import pegiSaga from '../Features/Pegi/Sagas/PegiSaga'
import serieSaga from '../Features/Serie/Sagas/SerieSaga'
import subgenreSaga from '../Features/Subgenre/Sagas/SubgenreSaga'

const sagaMiddleware = createSagaMiddleware()

export default configureStore({
  reducer: {
    user: userReducer,
    company: companyReducer,
    esrb: esrbReducer,
    game: gameReducer,
    gameplatform: gamePlatformReducer,
    genre: genreReducer,
    guide: guideReducer,
    pegi: pegiReducer,
    platform: platformReducer,
    serie: serieReducer,
    subgenre: subgenreReducer
  },
  middleware: [
    sagaMiddleware
  ]
})

sagaMiddleware.run(userSaga)
sagaMiddleware.run(companySaga)
sagaMiddleware.run(esrbSaga)
sagaMiddleware.run(gameSaga)
sagaMiddleware.run(gamePlatformSaga)
sagaMiddleware.run(genreSaga)
sagaMiddleware.run(guideSaga)
sagaMiddleware.run(pegiSaga)
sagaMiddleware.run(platformSaga)
sagaMiddleware.run(serieSaga)
sagaMiddleware.run(subgenreSaga)
