import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadGuides,
  loadGuideById,
  setGuides,
  setGuideData,
  saveGuide,
  removeGuide,
  updateState
} from '../Slice/GuideSlice'
import {
  createGuide,
  deleteGuide,
  obtainGuideByFilter,
  obtainGuideById,
  updateGuide
} from '../Service/GuideService'

function * fetchGuides () {
  try {
    const { filter } = yield select((state) => state.guide)
    const data = yield call(obtainGuideByFilter, filter)
    yield put(setGuides(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchGuideById () {
  try {
    const { keyId } = yield select((state) => state.guide)
    const data = yield call(obtainGuideById, keyId)
    yield put(setGuideData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storeGuide () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.guide)
    if (isValid) {
      if (keyId > 0) {
        yield call(updateGuide, keyId, form)
      } else {
        yield call(createGuide, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * eraseGuide (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const guideId = action.payload
    yield call(deleteGuide, guideId)

    // reload list
    const { filter } = yield select((state) => state.guide)
    const data = yield call(obtainGuideByFilter, filter)
    yield put(setGuides(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * guideSaga () {
  yield takeEvery(loadGuides, fetchGuides)
  yield takeEvery(loadGuideById, fetchGuideById)
  yield takeEvery(saveGuide, storeGuide)
  yield takeEvery(removeGuide, eraseGuide)
}

export default guideSaga
