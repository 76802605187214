import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form } from 'semantic-ui-react'
import { object, string } from 'yup'
import { setPlatformData } from '../Slice/PlatformSlice'
import { useValidateForm } from '../../Common/Hooks/useValidateForm'
import SearchTag from '../../Tag/Views/SearchTag'

export function PlatformForm () {
  const { t } = useTranslation()
  const { form: platform, isLoading, isDirty } = useSelector((state) => state.platform)
  const dispatch = useDispatch()

  const platformSchema = object({
    name: string().required(t('generic.validation.name')),
    abbr: string().required(t('generic.validation.abbr'))
  })
  const [errors, validate, handleChange] = useValidateForm(platform, platformSchema)

  const updateFormData = (data, isValid) => {
    dispatch(setPlatformData({ data, isValid }))
  }

  useEffect(() => {
    validate(platform)
  }, [isDirty])

  return (
    <Form autoComplete='off' loading={isLoading}>
      <Form.Group widths='equal'>
        <Form.Input
          label={ t('generic.name') }
          placeholder={ t('generic.name') }
          name='name'
          required
          value={platform.name}
          error={isDirty && errors.name}
          onChange={ handleChange(updateFormData) }
        />
        <Form.Input
          label={ t('generic.abbr') }
          placeholder={ t('generic.abbr') }
          name='abbr'
          required
          value={platform.abbr}
          error={isDirty && errors.abbr}
          onChange={ handleChange(updateFormData) }
        />
      </Form.Group>
      <Form.Group>
        <SearchTag value={platform.tag} onChange={ handleChange(updateFormData) } />
      </Form.Group>
      <Form.Field
        label={ t('generic.description') }
        control='textarea'
        rows='5'
        className='no-resize'
        name='description'
        value={platform.description}
        onChange={ handleChange(updateFormData) } />
    </Form>
  )
}
