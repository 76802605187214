import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadSubgenres,
  loadSubgenreById,
  setSubgenres,
  setSubgenreData,
  saveSubgenre,
  removeSubgenre,
  updateState
} from '../Slice/SubgenreSlice'
import {
  obtainSubgenreByFilter,
  obtainSubgenreById,
  createSubgenre,
  updateSubgenre,
  deleteSubgenre
} from '../Service/SubgenreService'

function * fetchSubgenres () {
  try {
    const { filter } = yield select(state => state.subgenre)
    const data = yield call(obtainSubgenreByFilter, filter)
    yield put(setSubgenres(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchSubgenreById () {
  try {
    const { keyId } = yield select((state) => state.subgenre)
    const data = yield call(obtainSubgenreById, keyId)
    yield put(setSubgenreData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storeSubgenre () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.subgenre)
    if (isValid) {
      if (keyId > 0) {
        yield call(updateSubgenre, keyId, form)
      } else {
        yield call(createSubgenre, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * eraseSubgenre (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const keyId = action.payload
    yield call(deleteSubgenre, keyId)

    // reload list
    const { filter } = yield select(state => state.subgenre)
    const data = yield call(obtainSubgenreByFilter, filter)
    yield put(setSubgenres(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * subgenreSaga () {
  yield takeEvery(loadSubgenres, fetchSubgenres)
  yield takeEvery(loadSubgenreById, fetchSubgenreById)
  yield takeEvery(saveSubgenre, storeSubgenre)
  yield takeEvery(removeSubgenre, eraseSubgenre)
}

export default subgenreSaga
