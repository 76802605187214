import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadGames,
  loadGameById,
  setGames,
  setGameData,
  saveGame,
  removeGame,
  updateState
} from '../Slice/GameSlice'
import {
  obtainGameByFilter,
  obtainGameById,
  createGame,
  updateGame,
  deleteGame
} from '../Service/GameService'

function * fetchGames () {
  try {
    const { filter } = yield select(state => state.game)
    const data = yield call(obtainGameByFilter, filter)
    yield put(setGames(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchGameById () {
  try {
    const { keyId } = yield select((state) => state.game)
    const data = yield call(obtainGameById, keyId)
    yield put(setGameData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storeGame () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.game)
    if (isValid) {
      if (keyId > 0) {
        yield call(updateGame, keyId, form)
      } else {
        yield call(createGame, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * eraseGame (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const gameId = action.payload
    yield call(deleteGame, gameId)

    // reload list
    const { filter } = yield select(state => state.game)
    const data = yield call(obtainGameByFilter, filter)
    yield put(setGames(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * gameSaga () {
  yield takeEvery(loadGames, fetchGames)
  yield takeEvery(loadGameById, fetchGameById)
  yield takeEvery(saveGame, storeGame)
  yield takeEvery(removeGame, eraseGame)
}

export default gameSaga
