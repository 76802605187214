import './css/SmallBox.css'

import { Checkbox as ReactCheckbox } from 'semantic-ui-react'

export function Checkbox ({ className, disabled = false, isToggle, label, name, value, onChange }) {
  return (
    <ReactCheckbox
      className={className}
      checked={value}
      disabled={disabled}
      label={label}
      name={name}
      toggle={isToggle}
      onChange={onChange}
    />
  )
}
