import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { Form } from 'semantic-ui-react'
import { useValidateForm } from '../../Common/Hooks/useValidateForm'
import { setSubgenreData } from '../Slice/SubgenreSlice'
import SearchTag from '../../Tag/Views/SearchTag'

export default function SubgenreForm () {
  const { t } = useTranslation()
  const { form: subgenre, isLoading, isDirty } = useSelector((state) => state.subgenre)
  const dispatch = useDispatch()

  const subgenreSchema = object({
    name: string().required(t('generic.validation.name'))
  })
  const [errors, validate, handleChange] = useValidateForm(subgenre, subgenreSchema)

  const updateFormData = (data, isValid) => {
    dispatch(setSubgenreData({ data, isValid }))
  }

  useEffect(() => {
    validate(subgenre)
  }, [isDirty])

  return (
    <Form autoComplete='off' loading={isLoading}>
      <Form.Input
        label={ t('generic.name') }
        placeholder={ t('generic.name') }
        name='name'
        required
        value={subgenre.name}
        error={isDirty && errors.name}
        onChange={ handleChange(updateFormData) } />
      <Form.Group>
        <SearchTag value={subgenre.tag} onChange={ handleChange(updateFormData) } />
      </Form.Group>
    </Form>
  )
}
