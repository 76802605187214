import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './Layout'
import HomePage from '../Pages/HomePage'
import NotFoundPage from '../Pages/NotFoundPage'
import UnauthorizedPage from '../Pages/UnauthorizedPage'
// Resources
import PlatformListPage from '../Features/Platform/Page/PlatformListPage'
import PlatformEditPage from '../Features/Platform/Page/PlatformEditPage'
import CompanyListPage from '../Features/Company/Page/CompanyListPage'
import CompanyEditPage from '../Features/Company/Page/CompanyEditPage'
import SerieListPage from '../Features/Serie/Page/SerieListPage'
import SerieEditPage from '../Features/Serie/Page/SerieEditPage'
import GameListPage from '../Features/Game/Page/GameListPage'
import GameEditPage from '../Features/Game/Page/GameEditPage'
import GamePlatformFormPage from '../Features/GamePlatform/Page/GamePlatformFormPage'
import GenreListPage from '../Features/Genre/Page/GenreListPage'
import GenreEditPage from '../Features/Genre/Page/GenreEditPage'
import SubgenreListPage from '../Features/Subgenre/Page/SubgenreListPage'
import SubgenreEditPage from '../Features/Subgenre/Page/SubgenreEditPage'
import PegiListPage from '../Features/Pegi/Page/PegiListPage'
import PegiEditPage from '../Features/Pegi/Page/PegiEditPage'
import EsrbListPage from '../Features/Esrb/Page/EsrbListPage'
import EsrbEditPage from '../Features/Esrb/Page/EsrbEditPage'
import GuideEditPage from '../Features/Guide/Page/GuideEditPage'
import GuideListPage from '../Features/Guide/Page/GuideListPage'
// Admin
import AdminPage from '../Features/Admin/Page/AdminPage'

export default function Router () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <Layout><HomePage /></Layout> } />
        <Route path='/meristation/gamepedia' element={ <Layout><HomePage /></Layout> } />
        <Route path='/platform' element={ <Layout><PlatformListPage /></Layout> } />
        <Route path='/platform/:platformId' element={ <Layout><PlatformEditPage /></Layout> } />
        <Route path='/company' element={ <Layout><CompanyListPage /></Layout> } />
        <Route path='/company/:companyId' element={ <Layout><CompanyEditPage /></Layout> } />
        <Route path='/serie' element={ <Layout><SerieListPage /></Layout> } />
        <Route path='/serie/:serieId' element={ <Layout><SerieEditPage /></Layout> } />
        <Route path='/game' element={ <Layout><GameListPage /></Layout> } />
        <Route path='/game/:gameId' element={ <Layout><GameEditPage /></Layout> } />
        <Route path='/game/:gameId/platform/new' element={ <Layout><GamePlatformFormPage /></Layout> } />
        <Route path='/game/:gameId/platform/:platformId' element={ <Layout><GamePlatformFormPage /></Layout> } />
        <Route path='/genre' element={ <Layout><GenreListPage /></Layout> } />
        <Route path='/genre/:genreId' element={ <Layout><GenreEditPage /></Layout> } />
        <Route path='/subgenre' element={ <Layout><SubgenreListPage /></Layout> } />
        <Route path='/subgenre/:subgenreId' element={ <Layout><SubgenreEditPage /></Layout> } />
        <Route path='/pegi' element={ <Layout><PegiListPage /></Layout> } />
        <Route path='/pegi/:pegiId' element={ <Layout><PegiEditPage /></Layout> } />
        <Route path='/esrb' element={ <Layout><EsrbListPage /></Layout> } />
        <Route path='/esrb/:esrbId' element={ <Layout><EsrbEditPage /></Layout> } />
        <Route path='/guide' element={ <Layout><GuideListPage /></Layout> } />
        <Route path='/guide/:guideId' element={ <Layout><GuideEditPage /></Layout> } />
        <Route path='/admin' element={ <Layout><AdminPage /></Layout> } />
        <Route path='/unauthorized' element={ <UnauthorizedPage /> } />
        <Route path='*' element={ <Layout><NotFoundPage /></Layout> } />
      </Routes>
    </BrowserRouter>
  )
}
