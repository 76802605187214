export const formatToResponseJson = (guide) => {
  const pagesFormatted = guide?.pages.map((page, index) => {
    if (page.type === 'page') {
      return {
        type: 'page',
        title: page.title,
        isPublished: page.isPublished,
        path: page.path,
        url: page.url,
        id: index + 1
      }
    } else {
      const pagesMapped = page.pages.map((childPage, index) => {
        return {
          type: 'page',
          title: childPage.title,
          isPublished: childPage.isPublished,
          path: childPage.path,
          url: childPage.url,
          id: index + 1,
          pageGroupAssigned: page.title
        }
      })

      const pageGroup = {
        type: 'group',
        title: page.title,
        id: guide?.pages.length + 1,
        pages: pagesMapped
      }

      return pageGroup
    }
  })

  return {
    cover: guide.cover,
    id: guide.id,
    isPublished: guide.isPublished,
    path: guide.path,
    title: guide.title,
    pages: pagesFormatted
  }
}
