import { userLogout } from '../../Features/User/Service/UserService'

export const loginAzure = async (msalInstance) => {
  await msalInstance.initialize()
  const resp = await msalInstance.handleRedirectPromise()
  if (!resp) {
    return await refreshToken(msalInstance)
  }

  const { accessToken } = resp
  return accessToken
}

export const refreshToken = async (msalInstance) => {
  let accessToken = ''
  const [account] = msalInstance.getAllAccounts()

  if (!account) {
    await msalInstance.loginRedirect()
    return accessToken
  }

  const accessTokenRequest = {
    scopes: ['user.read'],
    account
  }

  try {
    const resp = await msalInstance.acquireTokenSilent(accessTokenRequest)
    accessToken = resp.accessToken
  } catch (e) {
    msalInstance.acquireTokenRedirect(accessTokenRequest)
  }

  return accessToken
}

export const azureLogout = async (msalInstance) => {
  await userLogout()
  await msalInstance.logoutRedirect()
}
