import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getGameFilters = () => ({
  name: '',
  alternativeName: '',
  website: ''
})

const getGameForm = () => ({
  name: '',
  alternativeName: '',
  tag: '',
  description: '',
  website: '',
  cover: '',
  coverTemplate: '',
  coverDeleted: '',
  originalCover: '',
  headerImage: '',
  headerImageTemplate: '',
  headerImageDeleted: '',
  originalHeaderImage: '',
  album: '',
  guide: '',
  validGameId: 1,
  serieId: null,
  esrbId: null,
  pegiAgeId: null,
  genres: [],
  pegies: [],
  subgenres: [],
  platforms: []
})

export const gameSlice = createSlice({
  name: 'game',
  initialState: makeInitialState(getGameFilters(), getGameForm()),
  reducers: {
    loadGames: (state, action) => {
      loadResourceList(state, action, getGameFilters())
    },
    setGames: (state, action) => {
      setResourceList(state, action)
    },
    newGame: (state) => {
      newResource(state, getGameForm())
    },
    loadGameById: (state, action) => {
      loadResourceById(state, action, getGameForm())
    },
    setGameData: (state, action) => {
      setResourceData(state, action)
    },
    saveGame: (state) => {
      saveResource(state)
    },
    removeGame: (state) => {
      removeResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    },
    addPlatform: (state, action) => {
      state.form.platforms = action.payload
    },
    editPlatform: (state, action) => {
      state.form.platforms = state.form.platforms.map((platform) => {
        if (platform.platformId === action.payload.platformId) {
          return action.payload
        }
        return platform
      })
    },
    deletePlatofrm: (state, action) => {
      state.form.platforms = state.form.platforms.filter(platform => platform.platformId !== action.payload)
    },
    setPlatforms: (state, action) => {
      state.form.platforms = action.payload
    },
    setHeaderImage: (state, action) => {
      state.form.headerImage = action.payload
    },
    setHeaderTemplate: (state, action) => {
      const { image, original } = action.payload

      state.form.headerImage = image
      state.form.originalHeaderImage = original
    },
    setCoverImage: (state, action) => {
      state.form.cover = action.payload
    },
    setCoverTemplate: (state, action) => {
      const { image, original } = action.payload

      state.form.cover = image
      state.form.originalCover = original
    },
    uploadImageToBucket: (state) => {
      saveResource(state)
    },
    setDescription: (state, action) => {
      state.form.description = action.payload
    },
    setHeaderImageDeleted: (state, action) => {
      state.form.headerImageDeleted = action.payload
    },
    setCoverImageDeleted: (state, action) => {
      state.form.coverDeleted = action.payload
    },
    resetGame: (state) => {
      state.form = {
        name: '',
        alternativeName: '',
        tag: '',
        description: '',
        website: '',
        cover: '',
        coverTemplate: '',
        coverDeleted: '',
        originalCover: '',
        headerImage: '',
        headerImageTemplate: '',
        headerImageDeleted: '',
        originalHeaderImage: '',
        album: '',
        guide: '',
        validGameId: 1,
        serieId: null,
        esrbId: null,
        pegiAgeId: null,
        genres: [],
        pegies: [],
        subgenres: [],
        platforms: []
      }
    }
  }
})

export const {
  addPlatform,
  deletePlatofrm,
  editPlatform,
  loadGames,
  setGames,
  newGame,
  loadGameById,
  setGameData,
  saveGame,
  removeGame,
  updateState,
  setPlatforms,
  setHeaderImage,
  setHeaderTemplate,
  setCoverImage,
  setCoverTemplate,
  uploadImageToBucket,
  setDescription,
  setHeaderImageDeleted,
  setCoverImageDeleted,
  resetGame
} = gameSlice.actions

export default gameSlice.reducer
