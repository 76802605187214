import { Button, Grid, Icon, Message, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export default function SendToMetacriticModal ({ open, title, message, metacriticMessage, onCancel, onSend }) {
  const { t } = useTranslation()

  const onHandle = (fn) => {
    return () => {
      if (typeof fn === 'function') {
        fn()
      }
    }
  }

  return (
    <Modal open={open}>
      <Modal.Header className='bg-info'>
        <Icon name='send' size='small' />
        <span className='ms-3'>
          { title }
        </span>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Column width={3} textAlign='center'>
            <Icon name='send' size='huge' color='red' />
          </Grid.Column>
          <Grid.Column width={10}>
            <p>{ message }</p>
            <p>{metacriticMessage}</p>
            <Message className='mt-4' warning icon='exclamation triangle' content={ t('disclaimer.delete') } />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          labelPosition='left'
          icon='arrow alternate circle left'
          content={ t('generic.cancel') }
          type='button'
          onClick={onHandle(onCancel)}
        />
        <Button
          color='green'
          labelPosition='left'
          icon='send'
          content={ t('generic.send') }
          type='button'
          onClick={onHandle(onSend)}
        />
      </Modal.Actions>
    </Modal>
  )
}
