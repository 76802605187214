import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Form } from 'semantic-ui-react'
import { useDebounce } from '../../Common/Hooks/useDebounce'
import { obtainTabByTerm } from '../Service/TagService'

export default function SearchTag ({ error, name = 'tag', value, onChange, size = 7 }) {
  const { t } = useTranslation()
  const [tagList, setTagList] = useState([])
  const [termSearch, setTermSearch] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  const onDropdownChange = (e, data) => {
    if (typeof onChange === 'function') {
      onChange(e, data)
    }
  }
  const handleSearchTag = (ev, { searchQuery }) => {
    setTermSearch(searchQuery)
    if (searchQuery.length > 3) {
      searchTags()
    }
  }
  const searchTags = useDebounce(() => {
    setIsFetching(true)
    obtainTabByTerm(termSearch)
      .then((data) => {
        const list = data.map((element) => ({
          key: element.slug,
          text: element.slug,
          value: element.slug,
          description: element.prettyName
        }))
        setTagList(list)
        setIsFetching(false)
      })
      .catch(() => {
        setTagList([])
        setIsFetching(false)
        toast.warn(t('tag.error.timeout'))
      })
  }, 500)

  useEffect(() => {
    if (tagList.length === 0) {
      if (value) {
        setTagList([{
          key: value,
          text: value,
          value
        }])
      } else {
        setTagList([])
      }
    }
  }, [value])

  return (
    <Form.Dropdown
      error={error}
      fluid
      label={ t('generic.tag') }
      placeholder={ t('tag.search') }
      icon='search'
      search={(val) => val}
      selection
      options={tagList}
      loading={isFetching}
      name={name}
      value={value}
      onChange={ onDropdownChange }
      onSearchChange={handleSearchTag}
      width={size} />
  )
}
