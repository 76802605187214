import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Container, Grid, Icon, Message } from 'semantic-ui-react'
import { FaRegFlag } from 'react-icons/fa'
import { useEditHandleError } from '../../Common/Hooks/useEditHandleError'
import {
  loadPegiById,
  newPegi,
  savePegi,
  updateState
} from '../Slice/PegiSlice'
import PegiForm from '../Form/PegiForm'
import './css/PegiListPage.css'

export default function PegiEditPage () {
  const { t } = useTranslation()
  const {
    isLoading,
    isSuccess,
    isError,
    message
  } = useSelector((state) => state.pegi)
  const dispatch = useDispatch()
  const { pegiId } = useParams()
  const [errorList, goBack, handleError] = useEditHandleError('/pegi')

  useEffect(() => {
    if (pegiId === 'new') {
      dispatch(newPegi())
    } else {
      dispatch(loadPegiById(pegiId))
    }
  }, [])

  useEffect(() => {
    handleError(isSuccess, isError, message)
    dispatch(updateState())
  }, [isSuccess, isError, message])

  const onSavePegi = () => {
    dispatch(savePegi())
  }

  return (
    <Container className='pegi-list-page'>
      <div className="pegi-title pegi-division">
        <Grid columns={2}>
          <Grid.Column>
            <Icon size='large'><FaRegFlag /></Icon>
            {
              pegiId === 'new'
                ? (<span>{ t('pegi.new') }</span>)
                : (<>
                  <span>{ t('pegi.edit') }</span>
                  <small className='mx-3'># { pegiId }</small>
                </>)
            }
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <Button
              icon
              labelPosition='left'
              color='grey'
              size='large'
              disabled={isLoading}
              onClick={goBack}>
              <Icon name='arrow circle left' /> { t('generic.goBack') }
            </Button>
            <Button
              icon
              labelPosition='left'
              color='green'
              size='large'
              disabled={isLoading}
              onClick={onSavePegi}>
              <Icon name='save' /> { t('generic.save') }
            </Button>
          </Grid.Column>
        </Grid>
      </div>
      <div className="p-4">
        <PegiForm />
      </div>
      <div className="p-4">
        { errorList.length > 0 &&
          <Message error icon='exclamation triangle'
                   header={ t('generic.validation.onSave') }
                   list={errorList} />
        }
      </div>
    </Container>
  )
}
