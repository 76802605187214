import './css/SmallBox.css'

export function SmallBox ({ children, title, description, color, onClick }) {
  return (
    <div className="small-box bg-info" style={{ backgroundColor: color }}
         onClick={onClick}>
      <div className="inner">
        <h3>{title}</h3>
      </div>
      <div className="icon">
        {children}
      </div>
      <a href="#" className="small-box-footer">
        {description}
      </a>
    </div>
  )
}
