import './css/GuideForm.css'
import { object, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useModal } from '../../Common/Hooks/useModal'
import { useValidateForm } from '../../Common/Hooks/useValidateForm'

import { Button, Form } from 'semantic-ui-react'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import AddGuideGroupModal from '../Form/Modal/AddGuideGroupModal'
import AddGuidePageModal from '../Form/Modal/AddGuidePageModal'
import GroupIcon from '../Views/GroupIcon'
import LinkIcon from '../Views/LinkIcon'

import { setGuideData, setPages } from '../Slice/GuideSlice'

export default function GuideForm () {
  const { t } = useTranslation()

  const coverRegex = /^\/meristation(\/.*)?$/

  const guideSchema = object({
    title: string()
      .required(t('generic.validation.name')),
    cover: string()
      .required(t('generic.validation.cover'))
      .matches(coverRegex, t('guide.validation.cover'))
  })

  const dispatch = useDispatch()
  const {
    openAddGuideGroupModal,
    openAddGuidePageModal,
    handleToggleAddGuideGroup,
    handleToggleAddGuidePage
  } = useModal()
  const { form: guide, isLoading, isDirty } = useSelector((state) => state.guide)
  const [infoToEdit, setInfoToEdit] = useState(null)
  const [errors, validate, handleChange] = useValidateForm(guide, guideSchema)

  useEffect(() => {
    validate(guide)
  }, [isDirty])

  const updateFormData = (data, isValid) => {
    dispatch(setGuideData({ data, isValid }))
  }

  return (
    <Form autoComplete='off' loading={isLoading}>
      <Form.Group widths='equal'>
        <Form.Input
          error={isDirty && errors.title}
          label='Título de la Guía'
          placeholder='Título de la Guía'
          name='title'
          required
          value={guide?.title}
          onChange={handleChange(updateFormData)}
        />
        <Form.Input
          error={isDirty && errors.cover}
          label='URL de la Portada de la Guía'
          placeholder='URL de la Portada de la Guía'
          name='cover'
          required
          value={guide?.cover}
          onChange={handleChange(updateFormData)}
        />
      </Form.Group>
      <div className='guide-division' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h3 className='guide-title'>Guías</h3>
        </div>
        <div>
          <Button
            color='green'
            floated='right'
            size='large'
            type='button'
            onClick={() => {
              setInfoToEdit(null)
              handleToggleAddGuidePage()
            }}
          >
            Añadir Página
          </Button>
          <Button
            color='green'
            floated='right'
            size='large'
            type='button'
            onClick={() => {
              setInfoToEdit(null)
              handleToggleAddGuideGroup()
            }}
          >
            Añadir Grupo
          </Button>
        </div>
      </div>
      <NestedGuideList
        guide={guide}
        pages={guide?.pages}
        setInfoToEdit={setInfoToEdit}
        toggleGuideGroupModal={handleToggleAddGuideGroup}
        toggleGuidePageModal={handleToggleAddGuidePage}
      />
      <AddGuideGroupModal
        guide={guide}
        infoToEdit={infoToEdit}
        open={openAddGuideGroupModal}
        onChange={handleChange(updateFormData)}
        toggle={handleToggleAddGuideGroup}
      />
      <AddGuidePageModal
        guide={guide}
        infoToEdit={infoToEdit}
        open={openAddGuidePageModal}
        onChange={handleChange(updateFormData)}
        toggle={handleToggleAddGuidePage}
      />
    </Form>
  )
}

const NestedGuideList = ({ guide, pages, setInfoToEdit, toggleGuideGroupModal, toggleGuidePageModal }) => {
  const dispatch = useDispatch()

  const onEditGuide = (item) => {
    setInfoToEdit(item)

    if (item.type === 'group') {
      toggleGuideGroupModal()
    } else {
      toggleGuidePageModal()
    }
  }

  const onDeleteGuide = (item) => {
    const pagesFiltered = guide?.pages.filter((page) => page.title !== item.title)

    if (item.type === 'group' || !item.pageGroupAssigned) {
      dispatch(setPages(pagesFiltered))
    } else {
      const pagesFiltered = guide?.pages.filter((page) => page.title !== item.pageGroupAssigned)
      const groupFound = guide?.pages.find((page) => page.title === item.pageGroupAssigned)
      const groupPagesFiltered = groupFound?.pages.filter((childPage) => childPage.title !== item.title)

      dispatch(setPages([
        ...pagesFiltered,
        {
          type: 'group',
          title: groupFound.title,
          id: groupFound.id,
          pages: groupPagesFiltered
        }
      ]))
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) return

    const { draggableId } = result
    const dragabbleIdSplitted = draggableId.split('-')

    if (dragabbleIdSplitted[1] === 'group') {
      const pagesOrdered = Array.from(guide?.pages)
      const [movedGuide] = pagesOrdered.splice(result.source.index, 1)
      pagesOrdered.splice(result.destination.index, 0, movedGuide)

      dispatch(setPages(pagesOrdered))
    } else {
      if (!dragabbleIdSplitted[2]) {
        const pagesOrdered = Array.from(guide?.pages)
        const [movedGuide] = pagesOrdered.splice(result.source.index, 1)
        pagesOrdered.splice(result.destination.index, 0, movedGuide)

        dispatch(setPages(pagesOrdered))
      } else {
        const groupAssigned = dragabbleIdSplitted[2]
        const groupFound = guide?.pages.find((page) => page.title === groupAssigned)
        const pagesFiltered = guide?.pages.filter((page) => page.title !== groupFound.title)
        const pageIndex = guide?.pages.findIndex((page) => page.title === groupAssigned)

        const pagesOrdered = Array.from(groupFound?.pages)
        const [movedGuide] = pagesOrdered.splice(result.source.index, 1)
        pagesOrdered.splice(result.destination.index, 0, movedGuide)

        const filtered = pagesFiltered.map((page) => page)

        filtered.splice(pageIndex, 0, {
          type: 'group',
          title: groupFound.title,
          id: groupFound.id,
          pages: pagesOrdered
        })

        dispatch(setPages(filtered))
      }
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={(Math.floor(Math.random() * 500)).toString()} direction='vertical'>
        {(provided) => (
          <ul
            className='guide-list'
            ref={provided.innerRef}
            {...provided.droppableProps}
            >
              {pages?.map((item, index) => (
                <Draggable key={item.title} draggableId={!item.pageGroupAssigned ? `${item.title}-${item.type}` : `${item.title}-${item.type}-${item.pageGroupAssigned}`} index={index}>
                  {(provided) => (
                    <li
                      className='guide-list-items'
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className='guide-container-flex' style={{ gap: '1rem' }}>
                        {
                          item.type === 'page'
                            ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <LinkIcon />
                                <a className='guide-list-anchors' href={item.url} rel='noreferrer' target='_blank'>{item.title}</a>
                            </div>
                            : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <GroupIcon />
                                <span style={{ marginLeft: '0.5rem' }}>{item.title}</span>
                            </div>
                        }
                        <div>
                          <Button
                            className='guide-button'
                            color='green'
                            icon='edit'
                            title='Editar'
                            type='button'
                            onClick={() => onEditGuide(item)}
                          />
                          <Button
                            color='red'
                            icon='trash'
                            title='Borrar'
                            type='button'
                            onClick={() => onDeleteGuide(item)}
                          />
                        </div>
                      </div>
                      { item.pages?.length > 0 &&
                        <NestedGuideList
                          guide={guide}
                          pages={item.pages}
                          setInfoToEdit={setInfoToEdit}
                          toggleGuidePageModal={toggleGuidePageModal}
                        />
                      }
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}
