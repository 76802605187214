import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getPegiFilters = () => ({
  name: ''
})

const getPegiForm = () => ({
  name: '',
  image: ''
})

export const pegiSlice = createSlice({
  name: 'pegi',
  initialState: makeInitialState(getPegiFilters(), getPegiForm()),
  reducers: {
    loadPegiList: (state, action) => {
      loadResourceList(state, action, getPegiFilters())
    },
    setPegiList: (state, action) => {
      setResourceList(state, action)
    },
    newPegi: (state) => {
      newResource(state, getPegiForm())
    },
    loadPegiById: (state, action) => {
      loadResourceById(state, action, getPegiForm())
    },
    setPegiData: (state, action) => {
      setResourceData(state, action)
    },
    savePegi: (state) => {
      saveResource(state)
    },
    removePegi: (state) => {
      removeResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    }
  }
})

export const {
  loadPegiList,
  setPegiList,
  newPegi,
  loadPegiById,
  setPegiData,
  savePegi,
  removePegi,
  updateState
} = pegiSlice.actions

export default pegiSlice.reducer
