import axios from 'axios'

export const CoreAPI = axios.create({
  baseURL: process.env.REACT_APP_CORE_ENDPOINT,
  withCredentials: true // need for store cookie
})

export const buildParams = (filter) => {
  const sortFilter = buildSortQuery(filter.sort)
  const params = new URLSearchParams()
  Object.keys(filter).forEach((key) => {
    const value = filter[key]
    if (!value || typeof value === 'object') {
      return
    }
    params.append(key, filter[key])
  })
  if (sortFilter) {
    params.append('sort', sortFilter)
  }

  return params
}

export const buildSortQuery = (sort) => {
  if (typeof sort !== 'object') {
    return
  }

  return Object.keys(sort)
    .map((key) => {
      const order = String(sort[key]) === 'descending' ? 'desc' : 'asc'
      return `${key}:${order}`
    }).join(',')
}
