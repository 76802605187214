import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadPlatforms,
  loadPlatformById,
  setPlatforms,
  setPlatformData,
  savePlatform,
  updateState,
  removePlatform
} from '../Slice/PlatformSlice'
import {
  obtainPlatformsByFilter,
  obtainPlatformById,
  createPlatform,
  updatePlatform,
  deletePlatform
} from '../Service/PlatformService'

function * fetchPlatforms () {
  try {
    const { filter } = yield select((state) => state.platform)
    const data = yield call(obtainPlatformsByFilter, filter)
    yield put(setPlatforms(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchPlatformById () {
  try {
    const { keyId } = yield select((state) => state.platform)
    const data = yield call(obtainPlatformById, keyId)
    yield put(setPlatformData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storePlatform () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.platform)
    if (isValid) {
      if (keyId > 0) {
        yield call(updatePlatform, keyId, form)
      } else {
        yield call(createPlatform, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * erasePlatform (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const keyId = action.payload
    yield call(deletePlatform, keyId)

    // reload list
    const filter = yield select((state) => state.platform.filter)
    const data = yield call(obtainPlatformsByFilter, filter)
    yield put(setPlatforms(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * platformSaga () {
  yield takeEvery(loadPlatforms, fetchPlatforms)
  yield takeEvery(loadPlatformById, fetchPlatformById)
  yield takeEvery(savePlatform, storePlatform)
  yield takeEvery(removePlatform, erasePlatform)
}

export default platformSaga
