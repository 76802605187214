export const makeInitialState = (filters, form) => {
  return {
    keyId: 0,
    isLoading: false,
    filter: {
      ...filters,
      page: 1,
      limit: 10,
      sort: {
        id: 'descending'
      }
    },
    list: [],
    totalPages: 1,
    form,
    isValid: false,
    isDirty: false,
    isError: false,
    isSuccess: false,
    message: ''
  }
}

export const loadResourceList = (state, action, filters) => {
  const { payload = {} } = action
  const {
    page = 1,
    limit = 10,
    sort = { id: 'descending' }
  } = payload

  state.isLoading = true
  state.filter.page = page < 1 ? 1 : page
  state.filter.limit = limit < 1 ? 20 : limit
  state.filter.sort = sort

  Object.keys(filters).forEach((filterKey) => {
    state.filter[filterKey] = payload[filterKey] ?? ''
  })
}

export const setResourceList = (state, action) => {
  const {
    payload: {
      list = [],
      totalPages = 1
    } = {}
  } = action

  state.list = list
  state.totalPages = totalPages
  state.isLoading = false
}

export const newResource = (state, form) => {
  state.keyId = 0
  state.form = form
  state.isValid = false
  state.isDirty = false
  state.isError = false
  state.isSuccess = false
  state.message = ''
}

export const loadResourceById = (state, action, form) => {
  state.keyId = action.payload
  state.form = form
  state.isLoading = true
  state.isValid = true
  state.isDirty = false
  state.isError = false
  state.isSuccess = false
  state.message = ''
}

export const setResourceData = (state, action) => {
  const {
    payload: {
      data = undefined,
      isValid = undefined
    } = {}
  } = action

  state.isLoading = false
  if (undefined !== data) {
    state.form = data
  }
  if (undefined !== isValid) {
    state.isValid = !!isValid
  }
}

export const saveResource = (state) => {
  state.isLoading = true
  state.isDirty = true
  state.isError = false
  state.isSuccess = false
  state.message = ''
}

export const removeResource = (state) => {
  state.isLoading = true
  state.isError = false
  state.isSuccess = false
  state.message = ''
}

export const updateResource = (state, action) => {
  const {
    payload: {
      success = false,
      error = ''
    } = {}
  } = action

  state.isLoading = false
  if (error) {
    state.isError = true
    state.message = error
  } else {
    state.isError = false
    state.message = ''
    if (success) {
      state.isSuccess = success
    } else {
      state.isSuccess = false
    }
  }
}
