import { Button, Container, Icon } from 'semantic-ui-react'

export default function UnauthorizedPage () {
  return (
    <Container className='mt-5'>
      <h1>
        <Icon name='ban' size='big' color='red' />
        No esta autorizado
      </h1>
      <p>No dispone de permisos para acceder a <strong>Gamepedia</strong></p>
      <p>Pongase en contacto con su administrador</p>
      <Button as='a' href='/'>
        <Icon name='arrow alternate circle left outline' />
        Volver
      </Button>
    </Container>
  )
}
