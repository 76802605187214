import { Icon, Menu } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getMenuData } from './helpers/MenuHelper'
import './css/Aside.css'
import { useSelector } from 'react-redux'

export default function Aside () {
  const { isAdmin } = useSelector((state) => state.user)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const items = getMenuData(isAdmin)

  const handleItemSelected = (item) => {
    navigate(item.path)
  }

  return (
    <Menu vertical className='aside-menu'>
      { items.map((item) => (
        <Menu className='aside-item' key={item.name} onClick={() => handleItemSelected(item)} style={{ color: item.color }}>
          <Icon size='big'>{ item.icon }</Icon>
          <span className='menu-text'>{ t(item.name) }</span>
        </Menu>
      )) }
    </Menu>
  )
}
