import { buildParams, CoreAPI } from '../../../Services/CoreAPI'
import { formatToRequestJson, formatToResponseJson } from '../Helpers'

export const obtainGameByFilter = async (filter) => {
  const params = buildParams(filter)
  const endpoint = `/v1/game?${params.toString()}`

  const resp = await CoreAPI.get(endpoint)

  const {
    data: list = [],
    pagination: { totalPages = 1 } = {}
  } = resp

  if (list?.data) {
    return { list: list.data, totalPages }
  } else {
    return { list, totalPages }
  }
}

export const obtainGameById = async (gameId) => {
  const endpoint = `/v1/game/${gameId}`
  const resp = await CoreAPI.get(endpoint)

  if (resp.data?.data) {
    const formatDataToResponse = formatToResponseJson(resp.data.data)
    return formatDataToResponse
  }

  const formatDataToResponse = formatToResponseJson(resp.data)
  return formatDataToResponse
}

export const createGame = async (data) => {
  const endpoint = '/v1/game'
  const formatDataToRequest = formatToRequestJson(data)
  const resp = await CoreAPI.post(endpoint, formatDataToRequest)
  return resp.data
}

export const uploadImage = async ({ image, original, type }) => {
  const endpoint = 'v1/game/image'

  const formData = new FormData()
  formData.append('image', image, 'image.jpg')
  formData.append('original', original, 'original.jpg')
  formData.append('type', type)

  const resp = await CoreAPI.post(endpoint, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return resp.data
}

export const deleteImage = async (urlPath) => {
  const endpoint = '/v1/game/image'

  const resp = await CoreAPI.delete(endpoint, {
    data: {
      path: urlPath
    }
  })

  return resp.data
}

export const updateGame = async (gameId, data) => {
  const endpoint = `/v1/game/${gameId}`
  const formatDataToRequest = formatToRequestJson(data)
  const resp = await CoreAPI.put(endpoint, formatDataToRequest)
  return resp.data
}

export const deleteGame = async (gameId) => {
  const endpoint = `/v1/game/${gameId}`
  const resp = await CoreAPI.delete(endpoint)
  return resp.data
}
