import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'

export default function SearchGuideGroups ({ name, list, value, onChange }) {
  const { t } = useTranslation()

  const onDropdownChange = (e, data) => {
    if (typeof onChange === 'function') {
      onChange(e, data)
    }
  }

  return (
    <Form.Dropdown
      fluid
      name={name}
      options={list
        .filter(page => page.type === 'group')
        .map((element) => ({
          key: element.title,
          text: element.title,
          value: element.title,
          description: element.title
        }))}
      placeholder={t('guide.groupSearch')}
      selection
      value={value}
      onChange={onDropdownChange}
    />
  )
}
