import { useState } from 'react'

export const useModal = () => {
  const [openAddGuideGroupModal, setOpenAddGuideGroupModal] = useState(false)
  const [openAddGuidePageModal, setOpenAddGuidePageModal] = useState(false)

  const handleOpenAddGuideGroup = () => setOpenAddGuideGroupModal(true)
  const handleCloseAddGuideGroup = () => setOpenAddGuideGroupModal(false)
  const handleToggleAddGuideGroup = () => setOpenAddGuideGroupModal(state => !state)

  const handleOpenAddGuidePage = () => setOpenAddGuidePageModal(true)
  const handleCloseAddGuidePage = () => setOpenAddGuidePageModal(false)
  const handleToggleAddGuidePage = () => setOpenAddGuidePageModal(state => !state)

  return {
    openAddGuideGroupModal,
    openAddGuidePageModal,
    handleOpenAddGuideGroup,
    handleCloseAddGuideGroup,
    handleToggleAddGuideGroup,
    handleOpenAddGuidePage,
    handleCloseAddGuidePage,
    handleToggleAddGuidePage
  }
}
