import './css/ModalImageCropper.css'

import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Image, Modal } from 'semantic-ui-react'

import ImageCropper from './ImageCropper'

import { setCoverImageDeleted, setCoverTemplate, setHeaderImageDeleted, setHeaderTemplate } from '../../Game/Slice/GameSlice'

const IMAGE_TYPES = [
  {
    name: 'headerImage',
    properties: {
      aspectRatio: 2,
      types: ['image/jpeg'],
      width: 1360
    }
  },
  {
    name: 'coverImage',
    properties: {
      aspectRatio: 2 / 3,
      types: ['image/jpeg'],
      width: 560
    }
  }
]

export default function ModalImageCropper ({ imageSrc, imageType, openTextModal, title }) {
  const dispatch = useDispatch()
  const [croppedImage, setCroppedImage] = useState()
  const [originalImage, setOriginalImage] = useState(null)
  const [open, setOpen] = useState()
  const [isImageSelected, setIsImageSelected] = useState(false)
  const [previewSrc, setPreviewSrc] = useState('')
  const [src, setSrc] = useState('')
  const { t } = useTranslation()

  const imageInformation = IMAGE_TYPES.find(type => type.name === imageType)

  useEffect(() => {
    if (imageSrc) {
      setSrc(imageSrc)
    }
  }, [imageSrc])

  const onImageReady = () => {
    setSrc(src)
    setOpen(false)

    if (imageInformation.name === 'headerImage') {
      dispatch(setHeaderTemplate({ image: croppedImage, original: originalImage }))
    } else {
      dispatch(setCoverTemplate({ image: croppedImage, original: originalImage }))
    }
  }

  const onImageDelete = () => {
    const srcPath = src.substring(src.indexOf('/meristation'))

    if (imageInformation.name === 'headerImage') {
      dispatch(setHeaderTemplate({ image: '', original: '' }))
      dispatch(setHeaderImageDeleted(srcPath))
    } else {
      dispatch(setCoverTemplate({ image: '', original: '' }))
      dispatch(setCoverImageDeleted(srcPath))
    }

    setIsImageSelected(false)
    setSrc('')
    setPreviewSrc('')
  }

  const onClose = () => {
    setIsImageSelected(false)
    setOpen(false)
  }

  return (
    <div className='container'>
      {
        src &&
          <div style={{ border: '1px solid black', padding: 20 }}>
            <Image src={src} style={{ maxHeight: '225px', width: 'auto' }} />
          </div>
      }
      <Modal
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        trigger={<Button style={{ marginTop: 5 }} primary={true}>{openTextModal}</Button>}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content image>
          <ImageCropper
            imageInformation={imageInformation}
            previewSrc={previewSrc}
            src={src}
            setCroppedImage={setCroppedImage}
            setIsImageSelected={setIsImageSelected}
            setOriginalImage={setOriginalImage}
            setPreviewSrc={setPreviewSrc}
            setSrc={setSrc}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={onClose}>
            {t('generic.close')}
          </Button>
          <Button
            color='red'
            disabled={!src || src?.length === 0}
            onClick={onImageDelete}
          >
            {t('generic.delete')}
          </Button>
          <Button
            color='green'
            disabled={!isImageSelected}
            onClick={onImageReady}
          >
            {t('generic.save')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}
