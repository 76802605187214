import { buildParams, CoreAPI } from '../../../Services/CoreAPI'

export const obtainTabByTerm = async (term) => {
  const params = buildParams({ term })
  const endpoint = `/v1/tag?${params.toString()}`

  const resp = await CoreAPI.get(endpoint)

  const { data: list = [] } = resp
  return list
}
