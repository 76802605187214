import { call, put, select, takeEvery } from 'redux-saga/effects'

import { updateState } from '../../Game/Slice/GameSlice'

import { createGamePlatform, sendReviewToMetacritic, reactivateMetacriticEmail } from '../Service/GamePlatformService'

import { saveGamePlatform, sendToMetacritic, setMetacriticSent, reactivateMetacritic } from '../Slice/GamePlatformSlice'

function * storeGamePlatform () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.gameplatform)
    if (isValid) {
      if (keyId > 0) {
        console.log('')
      } else {
        yield call(createGamePlatform, form)
      }
    }
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * sendTextToMetacritic () {
  try {
    const { keyId: gameId } = yield select((state) => state.game)
    const { form: gamePlatform } = yield select((state) => state.gameplatform)

    yield call(sendReviewToMetacritic, { gameId, platformId: gamePlatform.platformId, data: gamePlatform.metaCriticText })
    yield put(setMetacriticSent(true))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * reactivateMetacriticReview () {
  try {
    const { keyId: gameId } = yield select((state) => state.game)
    const { form: gamePlatform } = yield select((state) => state.gameplatform)

    yield call(reactivateMetacriticEmail, { gameId, platformId: gamePlatform.platformId })
    yield put(setMetacriticSent(false))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * gamePlatformSaga () {
  yield takeEvery(sendToMetacritic, sendTextToMetacritic)
  yield takeEvery(reactivateMetacritic, reactivateMetacriticReview)
  yield takeEvery(saveGamePlatform, storeGamePlatform)
}

export default gamePlatformSaga
