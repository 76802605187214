import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getGuideFilters = () => ({
  title: ''
})

const getGuideForm = () => ({
  title: '',
  cover: '',
  path: '',
  isPublished: false,
  groupName: '',
  pageGroupAssigned: '',
  pageName: '',
  pageUrl: '',
  pages: []
})

export const guideSlice = createSlice({
  name: 'guide',
  initialState: makeInitialState(getGuideFilters(), getGuideForm()),
  reducers: {
    loadGuides: (state, action) => {
      loadResourceList(state, action, getGuideFilters())
    },
    setGuides: (state, action) => {
      setResourceList(state, action)
    },
    newGuide: (state) => {
      newResource(state, getGuideForm())
    },
    loadGuideById: (state, action) => {
      loadResourceById(state, action, getGuideForm())
    },
    setGuideData: (state, action) => {
      setResourceData(state, action)
    },
    setGroupName: (state, action) => {
      state.form.groupName = action.payload
    },
    setPageName: (state, action) => {
      state.form.pageName = action.payload
    },
    setPageGroupAssigned: (state, action) => {
      state.form.pageGroupAssigned = action.payload
    },
    setPageUrl: (state, action) => {
      state.form.pageUrl = action.payload
    },
    setPages: (state, action) => {
      state.form.pages = action.payload
    },
    removeGuide: (state) => {
      removeResource(state)
    },
    resetGuidePages: (state) => {
      state.form.groupName = ''
      state.form.pageGroupAssigned = ''
      state.form.pageName = ''
      state.form.pageUrl = ''
    },
    saveGuide: (state) => {
      saveResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    }
  }
})

export const {
  loadGuides,
  setGuides,
  newGuide,
  loadGuideById,
  setGroupName,
  setPageName,
  setPageGroupAssigned,
  setPageUrl,
  setGuideData,
  saveGuide,
  setPages,
  removeGuide,
  resetGuidePages,
  updateState
} = guideSlice.actions

export default guideSlice.reducer
