import { CoreAPI } from '../../../Services/CoreAPI'

export const createGamePlatform = async (data) => {
  const endpoint = '/v1/gameplatform'
  const resp = await CoreAPI.post(endpoint, data)
  return resp.data
}

export const sendReviewToMetacritic = async ({ gameId, platformId, data }) => {
  const endpoint = `v1/meta-critic/mail/${gameId}/${platformId}`
  const resp = await CoreAPI.patch(endpoint, { text: data })
  return resp.data
}

export const reactivateMetacriticEmail = async ({ gameId, platformId }) => {
  const endpoint = `v1/meta-critic/force-mail/${gameId}/${platformId}`
  const resp = await CoreAPI.patch(endpoint)
  return resp.data
}
