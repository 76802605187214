import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button, Container, Icon } from 'semantic-ui-react'
import { AiOutlineInbox } from 'react-icons/ai'
import { loadSeries, removeSerie, updateState } from '../Slice/SerieSlice'
import ListTable from '../../Common/Views/ListTable'
import DeleteConfirmModal from '../../Common/Views/DeleteConfirmModal'
import './css/SerieListPage.css'

export default function SerieListPage () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    list,
    filter,
    totalPages,
    isLoading,
    isError,
    message
  } = useSelector((state) => state.serie)
  const [deleteSerie, setDeleteSerie] = useState({})

  const columns = [
    {
      name: 'id',
      title: 'generic.id',
      filter: false,
      props: {
        textAlign: 'right',
        width: 1
      }
    },
    {
      name: 'name',
      title: 'generic.name'
    }
  ]

  useEffect(() => {
    onReload(filter)
  }, [])

  const onReload = (payload) => {
    dispatch(loadSeries(payload))
  }

  useEffect(() => {
    if (isError && message) {
      toast.error(message)
      dispatch(updateState())
    }
  }, [isError, message])

  const onEdit = (serieId) => {
    navigate(`/serie/${serieId}`)
  }
  const onDelete = (serie) => {
    setDeleteSerie(serie)
  }

  const handleDeleteSerie = () => {
    dispatch(removeSerie(deleteSerie.id))
    setDeleteSerie({})
  }

  return (
    <Container className='serie-list-page'>
      <div className='serie-title'>
        <Icon size='large'><AiOutlineInbox /></Icon>
        <span>{ t('menu.series') }</span>
        <Button
          floated='right'
          icon
          labelPosition='left'
          primary
          size='large'
          onClick={() => navigate('/serie/new')}>
          <Icon name='inbox' /> { t('serie.new') }
        </Button>
      </div>
      <ListTable color='purple'
                 isLoading={isLoading}
                 columns={columns}
                 filter={filter}
                 data={list}
                 totalPages={totalPages}
                 onReload={onReload}
                 onEdit={onEdit}
                 onDelete={onDelete} />
      <>
        <DeleteConfirmModal open={ !!deleteSerie.id }
                            title={ t('serie.deleteTitle') }
                            message={ t('serie.deleteQuestion') }
                            item={ deleteSerie.name }
                            onCancel={ () => setDeleteSerie({}) }
                            onDelete={ handleDeleteSerie } />
      </>
    </Container>
  )
}
