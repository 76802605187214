import './css/GameForm.css'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'
import { object, string } from 'yup'

import { useValidateForm } from '../../Common/Hooks/useValidateForm'

import { setDescription, setGameData } from '../Slice/GameSlice'
import { setGamePlatform } from '../../GamePlatform/Slice/GamePlatformSlice'

import usePlatforms from '../../Platform/Hooks/usePlatforms'

import GamePlatformFormPage from '../../GamePlatform/Page/GamePlatformFormPage'
import ModalImageCropper from '../../Common/Views/ModalImageCropper'
import RichTextEditor from '../../Common/Views/RichTextEditor'
import SearchEsrb from '../../Esrb/Views/SearchEsrb'
import SearchPegiAges from '../../Pegi/Views/SearchPegiAges'
import SearchPegiCategories from '../../Pegi/Views/SearchPegiCategories'
import SearchSerie from '../../Serie/Views/SearchSerie'
import SearchTag from '../../Tag/Views/SearchTag'
import SearchGenre from '../../Genre/Views/SearchGenre'
import SearchSubgenre from '../../Subgenre/Views/SearchSubgenre'

export function GameForm () {
  const dispatch = useDispatch()
  const { gameId } = useParams()
  const { platforms, searchPlatforms } = usePlatforms()
  const { form: game, isLoading, isDirty } = useSelector((state) => state.game)
  const { form: gamePlatform } = useSelector((state) => state.gameplatform)
  const { t } = useTranslation()

  const websiteRegex = /^(https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z]+.*$/
  const albumGuideRegex = /^(https?:\/\/)(?:[a-zA-Z0-9-]+\.)?as\.com\/?.*\/meristation/

  const gameSchema = object({
    name: string()
      .required(t('generic.validation.name')),
    tag: string()
      .required(t('generic.validation.tag')),
    website: string()
      .test('is-url', t('game.validation.website'), value => validateRegex(value, websiteRegex)),
    album: string()
      .test('is-url', t('game.validation.album'), value => validateRegex(value, albumGuideRegex)),
    guide: string()
      .test('is-url', t('game.validation.guide'), value => validateRegex(value, albumGuideRegex))
  })

  const validateRegex = (value, regex) => {
    if (!value || value.trim() === '') {
      return true
    }

    return regex.test(value)
  }

  const [errors, validate, handleChange] = useValidateForm(game, gameSchema)

  const GOOGLE_BUCKET_PUBLIC_DOMAIN = `${process.env.REACT_APP_BUCKET_PUBLIC_DOMAIN}`

  useEffect(() => {
    validate(game)
  }, [isDirty])

  useEffect(() => {
    if (gameId !== 'new' && game.platforms.length > 0 && gamePlatform?.platformId === null) {
      dispatch(setGamePlatform(game.platforms[0]))
    }
  }, [game])

  useEffect(() => {
    searchPlatforms()
  }, [])

  const updateFormData = (data, isValid) => {
    dispatch(setGameData({ data, isValid }))
  }

  const onLoadPlatform = (platformId) => {
    const platformData = game?.platforms.find(platform => platform.platformId === platformId)
    dispatch(setGamePlatform(platformData))
  }

  return (
    <div>
      <Form autoComplete='off' loading={isLoading}>
        <Form.Group style={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
          <div>
            <ModalImageCropper
              imageSrc={game.headerImage?.length > 0 ? `${GOOGLE_BUCKET_PUBLIC_DOMAIN}${game?.headerImage}` : null}
              imageType='headerImage'
              openTextModal={t('game.headerImage')}
              title={t('game.headerImage')}
            />
          </div>
          <div>
            <ModalImageCropper
              imageSrc={game.cover?.length > 0 ? `${GOOGLE_BUCKET_PUBLIC_DOMAIN}${game?.cover}` : null}
              imageType='coverImage'
              openTextModal={t('game.coverImage')}
              title={t('game.coverImage')}
            />
          </div>
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            error={isDirty && errors.name}
            label={t('generic.name')}
            name='name'
            placeholder={t('generic.name')}
            required
            value={game.name}
            onChange={handleChange(updateFormData)}
          />
          <Form.Input
            label={t('generic.otherName')}
            name='alternativeName'
            placeholder={t('generic.otherName')}
            value={game.alternativeName}
            onChange={handleChange(updateFormData)}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <SearchTag
            error={isDirty && errors.tag}
            name='tag'
            value={game.tag}
            onChange={handleChange(updateFormData)}
          />
          <Form.Input
            error={isDirty && errors.website}
            label={t('generic.website')}
            name='website'
            placeholder={t('generic.website')}
            value={game.website}
            onChange={handleChange(updateFormData)}
          />
        </Form.Group>
        <RichTextEditor
          data={game?.description || ''}
          name='description'
          onChange={(value) => dispatch(setDescription(value))}
        />
        <div style={{ marginTop: '1rem' }}>
          <Form.Group widths='equal'>
            <Form.Input
              error={isDirty && errors.album}
              label={t('generic.album')}
              name='album'
              placeholder={t('generic.album')}
              value={game.album}
              onChange={handleChange(updateFormData)}
            />
            <Form.Input
              error={isDirty && errors.guide}
              label={t('guide.title')}
              name='guide'
              placeholder={t('guide.title')}
              value={game.guide}
              onChange={handleChange(updateFormData)}
            />
          </Form.Group>
        </div>
        <Form.Group widths='equal'>
          <SearchSerie
            name='serieId'
            value={game?.serieId}
            onChange={handleChange(updateFormData)}
          />
          <SearchGenre
            name='genres'
            value={game?.genres?.map((genreId) => genreId)}
            onChange={handleChange(updateFormData)}
          />
          <SearchSubgenre
            name='subgenres'
            value={game?.subgenres?.map((subgenreId) => subgenreId)}
            onChange={handleChange(updateFormData)}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <SearchPegiAges
            name='pegiAgeId'
            value={game?.pegiAgeId}
            onChange={handleChange(updateFormData)}
          />
          <SearchPegiCategories
            name='pegies'
            value={game.pegies}
            onChange={handleChange(updateFormData)}
          />
          <SearchEsrb
            name='esrbId'
            value={game?.esrbId}
            onChange={handleChange(updateFormData)}
          />
        </Form.Group>
        {
          !gamePlatform.platformId && game?.platforms.length === 0 &&
            <div style={{ display: 'flex', justifyContent: 'center', fontSize: 20 }}>
              <p>{t('game.platformsNotAdded')}</p>
            </div>
        }
      </Form>
      <GamePlatformFormPage
        platforms={platforms}
        onLoadPlatform={onLoadPlatform}
      />
    </div>
  )
}
