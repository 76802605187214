import { buildParams, CoreAPI } from '../../../Services/CoreAPI'

export const obtainPlatformsByFilter = async (filter) => {
  let endpoint = '/v1/platform'

  if (filter) {
    const params = buildParams(filter)
    endpoint = `/v1/platform?${params.toString()}`
  }

  const resp = await CoreAPI.get(endpoint)

  const {
    data: list = [],
    pagination: { totalPages = 1 } = {}
  } = resp

  if (list?.data) {
    return { list: list.data, totalPages }
  } else {
    return { list, totalPages }
  }
}

export const obtainPlatformById = async (platformId) => {
  const endpoint = `/v1/platform/${platformId}`
  const resp = await CoreAPI.get(endpoint)

  if (resp.data?.data) {
    return resp.data.data
  }

  return resp.data
}

export const createPlatform = async (data) => {
  const endpoint = '/v1/platform'
  const resp = await CoreAPI.post(endpoint, data)
  return resp.data
}

export const updatePlatform = async (platformId, data) => {
  const endpoint = `/v1/platform/${platformId}`
  const resp = await CoreAPI.put(endpoint, data)
  return resp.data
}

export const deletePlatform = async (platformId) => {
  const endpoint = `/v1/platform/${platformId}`
  const resp = await CoreAPI.delete(endpoint)
  return resp.data
}
