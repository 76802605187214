import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Container, Icon } from 'semantic-ui-react'
import { FaThLarge } from 'react-icons/fa'
import { loadGenres, removeGenre, updateState } from '../Slice/GenreSlice'
import ListTable from '../../Common/Views/ListTable'
import { toast } from 'react-toastify'
import DeleteConfirmModal from '../../Common/Views/DeleteConfirmModal'
import './css/GenreListPage.css'

export default function GenreListPage () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    list,
    filter,
    totalPages,
    isLoading,
    isError,
    message
  } = useSelector((state) => state.genre)
  const dispatch = useDispatch()
  const [deleteGenre, setDeleteGenre] = useState({})

  const columns = [
    {
      name: 'id',
      title: 'generic.id',
      filter: false,
      props: {
        textAlign: 'right',
        width: 1
      }
    },
    {
      name: 'name',
      title: 'generic.name'
    },
    {
      name: 'tag',
      title: 'generic.tag'
    }
  ]

  useEffect(() => {
    onReload(filter)
  }, [])

  const onReload = (payload) => {
    dispatch(loadGenres(payload))
  }

  useEffect(() => {
    if (isError && message) {
      toast.error(message)
      dispatch(updateState())
    }
  }, [isError, message])

  const onEdit = (genreId) => {
    navigate(`/genre/${genreId}`)
  }
  const onDelete = (genre) => {
    setDeleteGenre(genre)
  }

  const handleDeleteGenre = () => {
    dispatch(removeGenre(deleteGenre.id))
    setDeleteGenre({})
  }

  return (
    <Container className='genre-list-page'>
      <div className='genre-title'>
        <Icon size='large'><FaThLarge /></Icon>
        <span>{ t('menu.genres') }</span>
        <Button
          floated='right'
          icon
          labelPosition='left'
          primary
          size='large'
          onClick={() => navigate('/genre/new')}>
          <Icon name='th large' /> { t('genre.new') }
        </Button>
      </div>
      <ListTable color='olive'
                 isLoading={isLoading}
                 columns={columns}
                 filter={filter}
                 data={list}
                 totalPages={totalPages}
                 onReload={onReload}
                 onEdit={onEdit}
                 onDelete={onDelete} />
      <>
        <DeleteConfirmModal open={ !!deleteGenre.id }
                            title={ t('genre.deleteTitle') }
                            message={ t('genre.deleteQuestion') }
                            item={ deleteGenre.name }
                            onCancel={ () => setDeleteGenre({}) }
                            onDelete={ handleDeleteGenre } />
      </>
    </Container>
  )
}
