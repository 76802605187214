import 'react-image-crop/dist/ReactCrop.css'

import { Button } from 'semantic-ui-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCrop from 'react-image-crop'

export default function ImageCropper ({
  imageInformation,
  previewSrc,
  src,
  setCroppedImage,
  setIsImageSelected,
  setOriginalImage,
  setPreviewSrc,
  setSrc
}) {
  const [crop, setCrop] = useState({})
  const [error, setError] = useState('')
  const [image, setImage] = useState(null)
  const { t } = useTranslation()

  const selectImage = (file, maxWidth) => {
    if (file) {
      const { properties } = imageInformation

      if (!properties?.types.includes(file.type)) {
        setError(`${t('image.error.noFileSupported')}. Formato: JPG | ${t('image.minimumWidth')}: ${properties.width}px | ${t('image.minimumHeight')}: ${properties.width / properties.aspectRatio}px`)
        return
      }

      const img = document.createElement('img')
      img.onload = function () {
        if (this.width < properties.width || this.height < (properties.width / properties.aspectRatio)) {
          setError(`${t('image.error.noFileSupported')}. Formato: JPG / ${t('image.minimumWidth')}: ${properties.width}px ${t('image.minimumHeight')}: ${properties.width / properties.aspectRatio}px`)
          return
        }

        const height = this.width > maxWidth ? this.height * maxWidth / this.width : this.height
        const width = this.width > maxWidth ? maxWidth : this.width

        setCropProxy({
          x: 0,
          y: 0,
          height: height > width / properties.aspectRatio ? width / properties.aspectRatio : height,
          width: height < width / properties.aspectRatio ? height * properties.aspectRatio : width,
          unit: 'px'
        })
      }

      const imageReader = new FileReader()
      imageReader.onloadend = () => {
        setPreviewSrc(imageReader.result?.toString() || '')
        const binaryImage = base64ToBinary(imageReader.result)
        const originalImageBlob = new Blob([binaryImage], { type: file.type })
        setOriginalImage(originalImageBlob)
      }

      imageReader.readAsDataURL(file)
      img.src = window.URL.createObjectURL(file)
    }
  }

  const setCropProxy = (newCrop) => {
    const { properties } = imageInformation

    if (properties.aspectRatio) {
      if (newCrop.width !== crop.width) {
        newCrop.height = newCrop.width / properties.aspectRatio
      } else {
        newCrop.width = newCrop.height * properties.aspectRatio
      }
      if (image) {
        const scaleX = image.naturalWidth / image.width

        if (properties.width && newCrop.width * scaleX <= properties.width) {
          newCrop.width = properties.width / scaleX
          newCrop.height = newCrop.width / properties.aspectRatio
        }
        if (newCrop.x + newCrop.width > image.width) {
          newCrop.width = image.width - newCrop.x
          newCrop.height = newCrop.width / properties.aspectRatio
        }
        if (newCrop.y + newCrop.height > image.height) {
          newCrop.height = image.height - newCrop.y
          newCrop.width = image.height * properties.aspectRatio
        }
      }
    }

    setCrop(newCrop)
  }

  const cropImageNow = () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const img = new Image()
    img.src = previewSrc

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    canvas.width = crop.width
    canvas.height = crop.height

    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const base64Image = canvas.toDataURL('image/jpeg')
    const binaryImage = base64ToBinary(base64Image)
    const imageBlob = new Blob([binaryImage], { type: 'image/jpeg' })
    setSrc(base64Image)
    setIsImageSelected(true)
    setCroppedImage(imageBlob)
  }

  const base64ToBinary = (base64) => {
    var binaryString = atob(base64.split(',')[1])
    var binaryLength = binaryString.length
    var bytes = new Uint8Array(binaryLength)

    for (var i = 0; i < binaryLength; i++) {
      var ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    return bytes.buffer
  }

  /* const base64ToFile = (base64Image, filename, mimeType = 'image/jpeg') => {
    const byteCharacters = atob(base64Image)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: mimeType })
    const file = new File([blob], filename, { type: mimeType })
    return file
  } */

  return (
    <div style={{ width: '100%' }}>
      <center>
        <input
          accept="image/*"
          type="file"
          onChange={(e) => {
            setError('')
            selectImage(e.target.files[0], e.target.parentNode.offsetWidth)
          }}
        />
        {
          previewSrc.length &&
            <div style={{ marginBottom: '2rem' }}>
              <ReactCrop crop={crop} onChange={setCropProxy}>
                <img src={previewSrc} onLoad={(img) => setImage(img.currentTarget)} />
              </ReactCrop>
            </div>
        }
        {
          previewSrc.length &&
            <Button
              color='blue'
              content={t('generic.crop')}
              icon='hand scissors'
              labelPosition='left'
              onClick={cropImageNow}
            />
        }
        { src &&
          <div style={{ marginTop: '2rem' }}>
            <img src={src} style={{ height: '400px', width: 'auto' }} />
          </div>
        }
        { error.length > 0 &&
          <p style={{ color: 'red', fontSize: 16, marginTop: 10 }}>
            {error}
          </p>
        }
      </center>
    </div>
  )
}
