import { CoreAPI } from '../../../Services/CoreAPI'

export const obtainUser = async () => {
  const endpoint = '/login'
  const resp = await CoreAPI.get(endpoint)
  return resp.data
}

export const userLogout = async () => {
  const endpoint = '/logout'
  await CoreAPI.get(endpoint)
}
