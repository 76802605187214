import { takeEvery, put, call } from 'redux-saga/effects'
import { setToken, setUser } from '../Slice/UserSlice'
import { obtainUser } from '../Service/UserService'

function * fetchUser () {
  try {
    const user = yield call(obtainUser)
    yield put(setUser(user))
  } catch (error) {
    yield put(setUser({}))
  }
}

function * userSaga () {
  yield takeEvery(setToken, fetchUser)
}

export default userSaga
