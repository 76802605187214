import { Header as HeaderUI } from 'semantic-ui-react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import UserDropdown from '../Features/Header/UserDropdown'
import SwitchLanguage from '../Features/Header/SwitchLanguage'
import './css/Header.css'

export default function Header () {
  return (
    <HeaderUI block className='layout-header'>
      <span className='header-title'>Meristation</span>
      <span className='header-arrow'><BiRightArrowAlt /></span>
      <span className='header-home'>
        <Link to='/'>Gamepedia</Link>
      </span>
      <div className="header-options">
        <div>
          <SwitchLanguage />
          <UserDropdown />
        </div>
      </div>
    </HeaderUI>
  )
}
