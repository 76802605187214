import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadCompanies,
  loadCompanyById,
  setCompanies,
  setCompanyData,
  saveCompany,
  removeCompany,
  updateState
} from '../Slice/CompanySlice'
import {
  obtainCompaniesByFilter,
  obtainCompanyById,
  createCompany,
  updateCompany,
  deleteCompany
} from '../Service/CompanyService'

function * fetchCompanies () {
  try {
    const filter = yield select((state) => state.company.filter)
    const data = yield call(obtainCompaniesByFilter, filter)
    yield put(setCompanies(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchCompanyById () {
  try {
    const { keyId } = yield select((state) => state.company)
    const data = yield call(obtainCompanyById, keyId)
    yield put(setCompanyData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storeCompany () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.company)
    if (isValid) {
      if (keyId > 0) {
        yield call(updateCompany, keyId, form)
      } else {
        yield call(createCompany, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * eraseCompany (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const companyId = action.payload
    yield call(deleteCompany, companyId)

    // reload list
    const { filter } = yield select((state) => state.company)
    const data = yield call(obtainCompaniesByFilter, filter)
    yield put(setCompanies(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * companySaga () {
  yield takeEvery(loadCompanies, fetchCompanies)
  yield takeEvery(loadCompanyById, fetchCompanyById)
  yield takeEvery(saveCompany, storeCompany)
  yield takeEvery(removeCompany, eraseCompany)
}

export default companySaga
