import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getSubgenreFilters = () => ({
  name: '',
  tag: ''
})

const getSubgenreForm = () => ({
  name: '',
  tag: ''
})

export const subgenreSlice = createSlice({
  name: 'subgenre',
  initialState: makeInitialState(getSubgenreFilters(), getSubgenreForm()),
  reducers: {
    loadSubgenres: (state, action) => {
      loadResourceList(state, action, getSubgenreFilters())
    },
    setSubgenres: (state, action) => {
      setResourceList(state, action)
    },
    newSubgenre: (state) => {
      newResource(state, getSubgenreForm())
    },
    loadSubgenreById: (state, action) => {
      loadResourceById(state, action, getSubgenreForm())
    },
    setSubgenreData: (state, action) => {
      setResourceData(state, action)
    },
    saveSubgenre: (state) => {
      saveResource(state)
    },
    removeSubgenre: (state) => {
      removeResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    }
  }
})

export const {
  loadSubgenres,
  setSubgenres,
  newSubgenre,
  loadSubgenreById,
  setSubgenreData,
  saveSubgenre,
  removeSubgenre,
  updateState
} = subgenreSlice.actions

export default subgenreSlice.reducer
