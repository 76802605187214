import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getPlatformFilters = () => ({
  name: '',
  tag: '',
  abbr: ''
})

const getPlatformForm = () => ({
  name: '',
  abbr: '',
  tag: '',
  description: ''
})

export const platformSlice = createSlice({
  name: 'platform',
  initialState: makeInitialState(getPlatformFilters(), getPlatformForm()),
  reducers: {
    loadPlatforms: (state, action) => {
      loadResourceList(state, action, getPlatformFilters())
    },
    setPlatforms: (state, action) => {
      setResourceList(state, action)
    },
    newPlatform: (state) => {
      newResource(state, getPlatformForm())
    },
    loadPlatformById: (state, action) => {
      loadResourceById(state, action, getPlatformForm())
    },
    setPlatformData: (state, action) => {
      setResourceData(state, action)
    },
    savePlatform: (state) => {
      saveResource(state)
    },
    removePlatform: (state) => {
      removeResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    }
  }
})

export const {
  loadPlatforms,
  setPlatforms,
  newPlatform,
  loadPlatformById,
  setPlatformData,
  savePlatform,
  removePlatform,
  updateState
} = platformSlice.actions

export default platformSlice.reducer
