import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getCompanyFilters = () => ({
  name: '',
  tag: ''
})

const getCompanyForm = () => ({
  name: '',
  tag: '',
  url: '',
  description: ''
})

export const companySlice = createSlice({
  name: 'company',
  initialState: makeInitialState(getCompanyFilters(), getCompanyForm()),
  reducers: {
    loadCompanies: (state, action) => {
      loadResourceList(state, action, getCompanyFilters())
    },
    setCompanies: (state, action) => {
      setResourceList(state, action)
    },
    newCompany: (state) => {
      newResource(state, getCompanyForm())
    },
    loadCompanyById: (state, action) => {
      loadResourceById(state, action, getCompanyForm())
    },
    setCompanyData: (state, action) => {
      setResourceData(state, action)
    },
    saveCompany: (state) => {
      saveResource(state)
    },
    removeCompany: (state) => {
      removeResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    }
  }
})

export const {
  loadCompanies,
  setCompanies,
  newCompany,
  loadCompanyById,
  setCompanyData,
  saveCompany,
  removeCompany,
  updateState
} = companySlice.actions

export default companySlice.reducer
