import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Container, Grid, Icon, Message } from 'semantic-ui-react'
import { FaThLarge } from 'react-icons/fa'
import { useEditHandleError } from '../../Common/Hooks/useEditHandleError'
import {
  loadGenreById,
  newGenre,
  saveGenre,
  updateState
} from '../Slice/GenreSlice'
import GenreForm from '../Form/GenreForm'
import './css/GenreListPage.css'

export default function GenreEditPage () {
  const { t } = useTranslation()
  const {
    isLoading,
    isSuccess,
    isError,
    message
  } = useSelector((state) => state.genre)
  const dispatch = useDispatch()
  const { genreId } = useParams()
  const [errorList, goBack, handleError] = useEditHandleError('/genre')

  useEffect(() => {
    if (genreId === 'new') {
      dispatch(newGenre())
    } else {
      dispatch(loadGenreById(genreId))
    }
  }, [])

  useEffect(() => {
    handleError(isSuccess, isError, message)
    dispatch(updateState())
  }, [isSuccess, isError, message])

  const onSaveGenre = () => {
    dispatch(saveGenre())
  }

  return (
    <Container className='genre-list-page'>
      <div className='genre-title genre-division'>
        <Grid columns={2}>
          <Grid.Column>
            <Icon size='large'><FaThLarge /></Icon>
            {
              genreId === 'new'
                ? (<span>{ t('genre.new') }</span>)
                : (<>
                  <span>{ t('genre.edit') }</span>
                  <small className='mx-3'># { genreId }</small>
                </>)
            }
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <Button
              icon
              labelPosition='left'
              color='grey'
              size='large'
              disabled={isLoading}
              onClick={goBack}>
              <Icon name='arrow circle left' /> { t('generic.goBack') }
            </Button>
            <Button
              icon
              labelPosition='left'
              color='green'
              size='large'
              disabled={isLoading}
              onClick={onSaveGenre}>
              <Icon name='save' /> { t('generic.save') }
            </Button>
          </Grid.Column>
        </Grid>
      </div>
      <div className="p-4">
        <GenreForm />
      </div>
      <div className="p-4">
        { errorList.length > 0 &&
          <Message error icon='exclamation triangle'
                   header={ t('generic.validation.onSave') }
                   list={errorList} />
        }
      </div>
    </Container>
  )
}
