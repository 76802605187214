import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getGenreFilters = () => ({
  name: '',
  tag: ''
})

const getGenreForm = () => ({
  name: '',
  tag: '',
  description: ''
})

export const genreSlice = createSlice({
  name: 'genre',
  initialState: makeInitialState(getGenreFilters(), getGenreForm()),
  reducers: {
    loadGenres: (state, action) => {
      loadResourceList(state, action, getGenreFilters())
    },
    setGenres: (state, action) => {
      setResourceList(state, action)
    },
    newGenre: (state) => {
      newResource(state, getGenreForm())
    },
    loadGenreById: (state, action) => {
      loadResourceById(state, action, getGenreForm())
    },
    setGenreData: (state, action) => {
      setResourceData(state, action)
    },
    saveGenre: (state) => {
      saveResource(state)
    },
    removeGenre: (state) => {
      removeResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    }
  }
})

export const {
  loadGenres,
  setGenres,
  newGenre,
  loadGenreById,
  setGenreData,
  saveGenre,
  removeGenre,
  updateState
} = genreSlice.actions

export default genreSlice.reducer
