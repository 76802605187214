import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'

import { loadPegiList } from '../Slice/PegiSlice'

export default function SearchPegiAges ({ name, value, onChange, size = 7 }) {
  const dispatch = useDispatch()
  const { isLoading, list } = useSelector(state => state.pegi)
  const [selectedValue, setSelectedValue] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(loadPegiList({ limit: 100 }))
  }, [])

  const onDropdownChange = (e, data) => {
    if (typeof onChange === 'function') {
      onChange(e, data)
      setSelectedValue(data.value)
    }
  }

  return (
    <Form.Dropdown
      fluid
      label={t('pegi.age')}
      loading={isLoading}
      name={name}
      options={list.length > 0 ? list
        .filter((element) => element.name.match(/\+[0-9]+/))
        .map((element) => ({
          key: element.id,
          text: element.name,
          value: element.id,
          description: element.name
        })) : []}
      placeholder={t('pegi.search')}
      selection
      value={selectedValue === null ? value : selectedValue}
      width={size}
      onChange={onDropdownChange}
    />
  )
}
