import FileSaver from 'file-saver'

const FIELDS_TO_WRITE_TO_CSV = ['id', 'name', 'normalized', 'tag']

export const downloadToCsv = (data) => {
  const fields = Object
    .keys(data[0])
    .filter(field => FIELDS_TO_WRITE_TO_CSV.includes(field))

  const csvData = [fields.join(','),
    ...data
      .map(item => fields.map(f => item[f])
        .join(','))]
    .join('\n')

  const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
  FileSaver.saveAs(csvBlob, 'games_affected.csv')
}
