import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Container, Icon } from 'semantic-ui-react'
import { loadGuides, removeGuide, updateState } from '../Slice/GuideSlice'
import { BiEdit } from 'react-icons/bi'
import ListTable from '../../Common/Views/ListTable'
import DeleteConfirmModal from '../../Common/Views/DeleteConfirmModal'
import './css/GuideListPage.css'

export default function GuideListPage () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    list,
    filter,
    totalPages,
    isLoading,
    isError,
    message
  } = useSelector((state) => state.guide)
  const [deleteGuide, setDeleteGuide] = useState({})

  const columns = [
    {
      name: 'id',
      title: 'generic.id',
      filter: false,
      props: {
        textAlign: 'right',
        width: 1
      }
    },
    {
      name: 'title',
      title: 'generic.title'
    }
  ]

  useEffect(() => {
    onReload(filter)
  }, [])

  const onReload = (payload) => {
    dispatch(loadGuides(payload))
  }

  useEffect(() => {
    if (isError && message) {
      toast.error(message)
      dispatch(updateState())
    }
  }, [isError, message])

  const onEdit = (guideId) => {
    navigate(`/guide/${guideId}`)
  }

  const onDelete = (company) => {
    setDeleteGuide(company)
  }

  const handleDeleteGuide = () => {
    dispatch(removeGuide(deleteGuide.id))
    setDeleteGuide({})
  }

  return (
    <Container className='guide-list-page'>
      <div className='guide-title'>
        <Icon size='large'><BiEdit /></Icon>
        <span>{ t('menu.guides') }</span>
        <Button
          floated='right'
          icon
          labelPosition='left'
          primary
          size='large'
          onClick={() => navigate('/guide/new')}>
          <Icon name='edit' /> { t('guide.new') }
        </Button>
      </div>
      <ListTable color='red'
                 isLoading={isLoading}
                 columns={columns}
                 filter={filter}
                 data={list}
                 totalPages={totalPages}
                 onReload={onReload}
                 onEdit={onEdit}
                 onDelete={onDelete} />
      <>
        <DeleteConfirmModal open={ !!deleteGuide.id }
                            title={ t('company.deleteTitle') }
                            message={ t('company.deleteQuestion') }
                            item={ deleteGuide.name }
                            onCancel={ () => setDeleteGuide({}) }
                            onDelete={ handleDeleteGuide } />
      </>
    </Container>
  )
}
