import { CoreAPI } from '../../../Services/CoreAPI'

export const obtainTechnicalSpecificationByFilter = async (filter) => {
  let endpoint = '/v1/technical-specifications'

  if (filter) {
    endpoint = `/v1/technical-specifications/${filter}`
  }

  const resp = await CoreAPI.get(endpoint)

  const {
    data: list = [],
    pagination: { totalPages = 1 } = {}
  } = resp

  return { list, totalPages }
}

export const createTechnicalSpecification = async (data) => {
  const endpoint = '/v1/technical-specifications'

  const resp = await CoreAPI.post(endpoint, data)
  return resp.data
}

export const deleteTechnicalSpecification = async (type) => {
  const endpoint = `/v1/technical-specifications/${type}`
  const resp = await CoreAPI.delete(endpoint)
  return resp.data
}

export const updateTechnicalSpecification = async (technicalSpecification, specifications) => {
  const endpoint = `/v1/technical-specifications/${technicalSpecification}`

  const formatSpecifications = {
    values: [
      ...specifications
    ]
  }

  const resp = await CoreAPI.put(endpoint, formatSpecifications)
  return resp.data
}
