import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useEditHandleError = (path) => {
  const navigate = useNavigate()
  const [errorList, setErrorList] = useState([])

  const goBack = () => {
    navigate(path)
  }

  const handleError = (isSuccess, isError, message) => {
    if (isSuccess) {
      goBack()
    } else if (isError && message) {
      if (message === 404) {
        navigate('/not-found')
      } else {
        toast.error(message)
        setErrorList([message])
      }
    }
  }

  return [errorList, goBack, handleError]
}
