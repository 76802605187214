import { GamePlatformForm } from '../Form/GamePlatformForm'

export default function GamePlatformFormPage ({ platforms, onLoadPlatform }) {
  return (
    <GamePlatformForm
      platforms={platforms}
      onLoadPlatform={onLoadPlatform}
    />
  )
}
