import { CoreAPI } from '../../../Services/CoreAPI'

export const associateEntity = async (data) => {
  const endpoint = '/v1/game/entity/associate'
  const resp = await CoreAPI.patch(endpoint, data)
  return resp
}

export const desassociateEntity = async (data) => {
  const endpoint = '/v1/game/entity/desassociate'
  const resp = await CoreAPI.patch(endpoint, data)
  return resp
}
