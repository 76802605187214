import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { Form } from 'semantic-ui-react'
import { useValidateForm } from '../../Common/Hooks/useValidateForm'
import { setGenreData } from '../Slice/GenreSlice'
import SearchTag from '../../Tag/Views/SearchTag'

export default function GenreForm () {
  const { t } = useTranslation()
  const { form: genre, isLoading, isDirty } = useSelector((state) => state.genre)
  const dispatch = useDispatch()

  const genreSchema = object({
    name: string().required(t('generic.validation.name'))
  })
  const [errors, validate, handleChange] = useValidateForm(genre, genreSchema)

  const updateFormData = (data, isValid) => {
    dispatch(setGenreData({ data, isValid }))
  }

  useEffect(() => {
    validate(genre)
  }, [isDirty])

  return (
    <Form autoComplete='off' loading={isLoading}>
      <Form.Input
        label={ t('generic.name') }
        placeholder={ t('generic.name') }
        name='name'
        required
        value={genre.name}
        error={isDirty && errors.name}
        onChange={ handleChange(updateFormData) } />
      <Form.Group>
        <SearchTag value={genre.tag} onChange={ handleChange(updateFormData) } />
      </Form.Group>
      <Form.Field
        label={ t('generic.description') }
        control='textarea'
        rows='5'
        className='no-resize'
        name='description'
        value={genre.description}
        onChange={ handleChange(updateFormData) } />
    </Form>
  )
}
