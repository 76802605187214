import { Button, Grid, Header, Icon, Message, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export default function DeleteConfirmModal ({ open, title, message, item, onCancel, onDelete }) {
  const { t } = useTranslation()

  const onHandle = (fn) => {
    return () => {
      if (typeof fn === 'function') {
        fn()
      }
    }
  }

  return (
    <Modal open={open}>
      <Modal.Header className='bg-danger'>
        <Icon name='trash' size='small' />
        <span className='ms-3'>
          { title }
          { item && `: ${item}` }
        </span>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Column width={3} textAlign='center'>
            <Icon name='trash alternate' size='huge' color='red' />
          </Grid.Column>
          <Grid.Column width={10}>
            <p>{ message }</p>
            { item && <Header as='h4' color='red'>{ item }</Header> }
            <Message className='mt-4' warning icon='exclamation triangle' content={ t('disclaimer.delete') } />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey'
                labelPosition='left'
                icon='arrow alternate circle left'
                content={ t('generic.cancel') }
                onClick={ onHandle(onCancel) } />
        <Button color='red'
                labelPosition='left'
                icon='trash'
                content={ t('generic.delete') }
                onClick={ onHandle(onDelete) } />
      </Modal.Actions>
    </Modal>
  )
}
