import { Dropdown, Icon } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AiOutlineIdcard } from 'react-icons/ai'
import { HiOutlineMail } from 'react-icons/hi'
import { FiLogOut } from 'react-icons/fi'
import { azureLogout } from '../../Services/Azure/AzureService'
import { useMsal } from '@azure/msal-react'
import './css/UserDropdown.css'

export default function UserDropdown () {
  const { t } = useTranslation()
  const { instance } = useMsal()
  const { user } = useSelector((state) => state.user)

  const handleLogout = () => {
    azureLogout(instance).then()
  }

  return (
    <Dropdown icon='user circle' inline={true} className='user'>
      <Dropdown.Menu className='menu-position'>
        <Dropdown.Item disabled={true} className='menu-name'>
          <Icon size='big'><AiOutlineIdcard /></Icon>
          <span>{ user.displayName }</span>
        </Dropdown.Item>
        <Dropdown.Item disabled={true} className='menu-email'>
          <Icon size='big'><HiOutlineMail /></Icon>
          <span>{ user.name }</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogout}>
          <Icon size='big'><FiLogOut /></Icon>
          <span>{ t('header.logout') }</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
