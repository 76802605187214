import DatePicker from 'react-datepicker'

import './css/DatePicker.css'

export default function FormDatePicker ({ label, name, value, onChange }) {
  const dateToShow = value === null ? null : new Date(Number(`${value}000`))

  return (
    <div className='date-picker-container'>
      <span>{label}</span>
      <DatePicker
        className='date-picker'
        dateFormat='dd-MM-yyyy'
        name={name}
        placeholderText='dd-MM-yyyy'
        selected={dateToShow}
        onChange={(date) => onChange(name, date)}
      />
    </div>
  )
}
