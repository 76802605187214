import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form } from 'semantic-ui-react'
import { object, string } from 'yup'
import { useValidateForm } from '../../Common/Hooks/useValidateForm'
import { setEsrbData } from '../Slice/EsrbSlice'

export default function EsrbForm () {
  const { t } = useTranslation()
  const { form: esrb, isLoading, isDirty } = useSelector((state) => state.esrb)
  const dispatch = useDispatch()

  const esrbSchema = object({
    name: string().required(t('generic.validation.name'))
  })
  const [errors, validate, handleChange] = useValidateForm(esrb, esrbSchema)

  const updateFormData = (data, isValid) => {
    dispatch(setEsrbData({ data, isValid }))
  }

  useEffect(() => {
    validate(esrb)
  }, [isDirty])

  return (
    <Form autoComplete='off' loading={isLoading}>
      <Form.Input
        label={ t('generic.name') }
        placeholder={ t('generic.name') }
        name='name'
        required
        value={esrb.name}
        error={isDirty && errors.name}
        onChange={ handleChange(updateFormData) }
      />
    </Form>
  )
}
