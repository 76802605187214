import { ToastContainer } from 'react-toastify'
import Header from './Header'
import Aside from './Aside'
import './css/Layout.css'

export default function Layout ({ children }) {
  return (
    <>
      <Header />
      <div className="layout">
        <Aside />
        { children }
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}
