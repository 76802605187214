import { createSlice } from '@reduxjs/toolkit'
import {
  loadResourceById,
  loadResourceList,
  makeInitialState,
  newResource,
  removeResource,
  saveResource,
  setResourceData,
  setResourceList,
  updateResource
} from '../../Common/Slice/ResourceSlice'

const getSerieFilters = () => ({
  name: ''
})

const getSerieForm = () => ({
  name: ''
})

export const serieSlice = createSlice({
  name: 'serie',
  initialState: makeInitialState(getSerieFilters(), getSerieForm()),
  reducers: {
    loadSeries: (state, action) => {
      loadResourceList(state, action, getSerieFilters())
    },
    setSeries: (state, action) => {
      setResourceList(state, action)
    },
    newSerie: (state) => {
      newResource(state, getSerieForm())
    },
    loadSerieById: (state, action) => {
      loadResourceById(state, action, getSerieForm())
    },
    setSerieData: (state, action) => {
      setResourceData(state, action)
    },
    saveSerie: (state) => {
      saveResource(state)
    },
    removeSerie: (state) => {
      removeResource(state)
    },
    updateState: (state, action) => {
      updateResource(state, action)
    }
  }
})

export const {
  loadSeries,
  setSeries,
  newSerie,
  loadSerieById,
  setSerieData,
  saveSerie,
  removeSerie,
  updateState
} = serieSlice.actions

export default serieSlice.reducer
