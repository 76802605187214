import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '../Hooks/useDebounce'
import { Button, Grid, Icon, Input, Pagination, Select, Table } from 'semantic-ui-react'

export default function ListTable ({ color, isLoading, columns, filter, data, totalPages, onReload, onEdit, onDelete }) {
  const { t } = useTranslation()
  const { isAdmin } = useSelector((state) => state.user)
  const limitOptions = [
    { key: '5', value: 5, text: '5' },
    { key: '10', value: 10, text: '10' },
    { key: '15', value: 15, text: '15' },
    { key: '20', value: 20, text: '20' },
    { key: '25', value: 25, text: '25' },
    { key: '50', value: 50, text: '50' }
  ]

  const fieldFilters = {}
  columns
    .filter((col) => col.filter !== false)
    .forEach((col) => {
      fieldFilters[col.name] = filter[col.name]
    })
  const [filters, setFilters] = useState(fieldFilters)
  const totalColumns = columns.length + 1

  const columnSorted = (name) => (undefined !== filter.sort[name]) ? filter.sort[name] : null
  const sortColumn = (name) => {
    let order = 'ascending'
    if (undefined !== filter.sort[name]) {
      if (filter.sort[name] === 'ascending') {
        order = 'descending'
      }
    }

    const payload = {
      ...filter,
      sort: {
        [name]: order
      }
    }
    onReload(payload)
  }

  const getSearchPlaceholder = (fieldName) => {
    const options = { name: t(fieldName).toLowerCase() }
    return t('generic.searchBy', options)
  }

  const clearFilter = (name) => {
    const compiledFilters = {
      ...filters,
      [name]: ''
    }
    setFilters(compiledFilters)
    const payload = {
      ...filter,
      ...compiledFilters
    }
    onReload(payload)
  }

  const clearFilters = () => {
    const cleanFilters = {}
    Object.keys(filters).forEach((key) => {
      cleanFilters[key] = ''
    })
    setFilters(cleanFilters)
    const payload = {
      ...filter,
      ...cleanFilters
    }
    onReload(payload)
  }

  const onSearch = (name) => {
    return (ev) => {
      const { value } = ev.target

      setFilters({
        ...filters,
        [name]: value
      })

      onListFilter()
    }
  }

  const onListFilter = useDebounce(() => {
    const payload = {
      ...filter,
      ...filters
    }

    onReload(payload)
  }, 500)

  const onLimitChanged = (ev, data) => {
    const { value } = data
    const payload = {
      ...filter,
      page: 1,
      limit: value
    }
    onReload(payload)
  }

  const onPageChanged = (ev, data) => {
    const { activePage } = data
    const payload = {
      ...filter,
      page: activePage
    }
    onReload(payload)
  }

  const getValue = (item, col) => {
    const value = item[col.name]
    if (typeof col.format === 'function') {
      return col.format(value, col, item)
    }
    return value
  }

  const onEditClicked = (itemId) => {
    if (typeof onEdit === 'function') {
      onEdit(itemId)
    }
  }

  const onDeleteClicked = (item) => {
    if (typeof onDelete === 'function') {
      onDelete(item)
    }
  }

  return (
    <Table color={color} celled selectable striped sortable>
      <Table.Header>
        <Table.Row>
          { columns.map((col) => (
            <Table.HeaderCell key={`col-${col.name}`}
                              sorted={columnSorted(col.name)}
                              onClick={ () => col.sort !== false && sortColumn(col.name) }>
              { t(col.title) }
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell>{ t('generic.operations') }</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          { columns.map((col) => (
            <Table.HeaderCell key={`filter-${col.name}`}>
              { col.filter !== false &&
                <Input fluid
                       icon='search'
                       iconPosition='left'
                       action={{
                         color: 'grey',
                         icon: 'remove',
                         disabled: !filters[col.name],
                         onClick: () => clearFilter(col.name)
                       }}
                       value={filters[col.name]}
                       placeholder={ getSearchPlaceholder(col.title) }
                       onChange={onSearch(col.name)} />
              }
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell textAlign='center'>
            <Button icon labelPosition='left'
                    title={ t('generic.clearFilters') }
                    onClick={clearFilters}>
              <Icon name='remove circle' />
              { t('generic.clear') }
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { data && data?.map((item) => (
          <Table.Row key={item.id}>
            { columns.map((col) => (
              <Table.Cell key={`${col.name}-${item[col.id]}`} {...col.props}>
                { getValue(item, col) }
              </Table.Cell>
            ))}
            <Table.Cell textAlign='center' width={2}>
              <Button icon='edit' color='green' title={ t('generic.edit') }
                      onClick={ () => onEditClicked(item.id) }/>
              { isAdmin &&
                <Button icon='trash' color='red' title={ t('generic.delete') }
                        onClick={ () => onDeleteClicked(item) }/>
              }
            </Table.Cell>
          </Table.Row>
        ))}
        { !isLoading && (!data || data.length === 0) &&
          <Table.Row>
            <Table.Cell colSpan={totalColumns} warning textAlign='center' verticalAlign='middle'>
              { t('generic.noRecordsFound') }
            </Table.Cell>
          </Table.Row>
        }
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={totalColumns}>
            <Grid columns={2} verticalAlign='middle'>
              <Grid.Column>
                { t('generic.pagination', { page: filter.page, total: totalPages || 0 }) },&nbsp;
                { t('generic.show') }&nbsp;&nbsp;
                <Select options={limitOptions} value={filter.limit} onChange={onLimitChanged} />
                &nbsp;&nbsp;{ t('generic.elements') }
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Pagination activePage={filter.page}
                            totalPages={totalPages || 0}
                            onPageChange={onPageChanged}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }} />
              </Grid.Column>
            </Grid>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}
