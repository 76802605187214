import { AiOutlineInbox, AiOutlineLaptop } from 'react-icons/ai'
import { GiGamepad } from 'react-icons/gi'
import { FaFlag, FaRegFlag, FaTh, FaThLarge } from 'react-icons/fa'
import { BsBriefcase } from 'react-icons/bs'
import { BiCog, BiEdit } from 'react-icons/bi'

export const getMenuData = (isAdmin = false) => {
  const items = [
    {
      name: 'menu.platforms',
      icon: <AiOutlineLaptop />,
      path: '/platform',
      color: '#46258d'
    },
    {
      name: 'menu.companies',
      icon: <BsBriefcase />,
      path: '/company',
      color: '#a9490a'
    },
    {
      name: 'menu.series',
      icon: <AiOutlineInbox />,
      path: '/serie',
      color: '#74258e'
    },
    {
      name: 'menu.games',
      icon: <GiGamepad />,
      path: '/game',
      color: '#175c92'
    },
    {
      name: 'menu.genres',
      icon: <FaThLarge />,
      path: '/genre',
      color: '#798910'
    },
    {
      name: 'menu.subgenres',
      icon: <FaTh />,
      path: '/subgenre',
      color: '#008079'
    },
    {
      name: 'menu.pegi',
      icon: <FaRegFlag />,
      path: '/pegi',
      color: '#815131'
    },
    {
      name: 'menu.esrb',
      icon: <FaFlag />,
      path: '/esrb',
      color: '#b08503'
    },
    {
      name: 'menu.guides',
      icon: <BiEdit />,
      path: '/guide',
      color: '#991a1a'
    }
  ]

  if (isAdmin) {
    items.push({
      name: 'menu.admin',
      icon: <BiCog />,
      path: '/admin',
      color: '#991a1a'
    })
  }

  return items
}
