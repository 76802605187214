import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadPegiList,
  loadPegiById,
  setPegiList,
  setPegiData,
  savePegi,
  removePegi,
  updateState
} from '../Slice/PegiSlice'
import {
  obtainPegiByFilter,
  obtainPegiById,
  createPegi,
  updatePegi,
  deletePegi
} from '../Service/PegiService'

function * fetchPegiList () {
  try {
    const { filter } = yield select(state => state.pegi)
    const data = yield call(obtainPegiByFilter, filter)
    yield put(setPegiList(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchPegiById () {
  try {
    const { keyId } = yield select((state) => state.pegi)
    const data = yield call(obtainPegiById, keyId)
    yield put(setPegiData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storePegi () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.pegi)
    if (isValid) {
      if (keyId > 0) {
        yield call(updatePegi, keyId, form)
      } else {
        yield call(createPegi, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * erasePegi (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const keyId = action.payload
    yield call(deletePegi, keyId)

    // reload list
    const { filter } = yield select(state => state.pegi)
    const data = yield call(obtainPegiByFilter, filter)
    yield put(setPegiList(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * pegiSaga () {
  yield takeEvery(loadPegiList, fetchPegiList)
  yield takeEvery(loadPegiById, fetchPegiById)
  yield takeEvery(savePegi, storePegi)
  yield takeEvery(removePegi, erasePegi)
}

export default pegiSaga
