import { buildParams, CoreAPI } from '../../../Services/CoreAPI'

export const obtainCompaniesByFilter = async (filter) => {
  let endpoint = '/v1/company'

  if (filter) {
    const params = buildParams(filter)
    endpoint = `/v1/company?${params.toString()}`
  }

  const resp = await CoreAPI.get(endpoint)

  const {
    data: list = [],
    pagination: { totalPages = 1 } = {}
  } = resp

  if (list?.data) {
    return { list: list.data, totalPages }
  } else {
    return { list, totalPages }
  }
}

export const obtainCompanyById = async (companyId) => {
  const endpoint = `/v1/company/${companyId}`
  const resp = await CoreAPI.get(endpoint)

  if (resp.data?.data) {
    return resp.data.data
  }

  return resp.data
}

export const createCompany = async (data) => {
  const endpoint = '/v1/company'
  const resp = await CoreAPI.post(endpoint, data)
  return resp.data
}

export const updateCompany = async (companyId, data) => {
  const endpoint = `/v1/company/${companyId}`
  const resp = await CoreAPI.put(endpoint, data)
  return resp.data
}

export const deleteCompany = async (companyId) => {
  const endpoint = `/v1/company/${companyId}`
  const resp = await CoreAPI.delete(endpoint)
  return resp.data
}
