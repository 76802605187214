import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button, Form, Icon, Modal } from 'semantic-ui-react'

import { resetGuidePages, setGroupName, setPages } from '../../Slice/GuideSlice'
import { useEffect } from 'react'

export default function AddGuideGroupModal ({ guide, infoToEdit, open, onChange, toggle }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (open && infoToEdit) {
      dispatch(setGroupName(infoToEdit.title))
    }
  }, [open])

  const onAddGroupPage = () => {
    const { pages } = guide

    if (infoToEdit === null) {
      const pagesUpdated = [...pages, {
        type: 'group',
        title: guide?.groupName,
        id: guide.pages.length + 1,
        pages: []
      }]
      dispatch(setPages(pagesUpdated))
    } else {
      const pagesMapped = []

      for (const page of guide?.pages) {
        if (page.title !== infoToEdit.title) {
          pagesMapped.push(page)
        } else {
          pagesMapped.push({
            type: 'group',
            title: guide?.groupName,
            id: page.id,
            pages: page?.pages
          })
        }
      }

      dispatch(setPages(pagesMapped))
    }
    dispatch(resetGuidePages(true))
  }

  return (
    <Modal open={open}>
      <Modal.Header className='bg-info'>
        { infoToEdit === null ? 'Nuevo Grupo' : 'Editar Grupo' }
      </Modal.Header>
      <Modal.Content>
        <div style={{ alignItems: 'center', display: 'flex', gap: '1rem' }}>
          <p style={{ margin: 0 }}>Nombre</p>
          <Form.Input
            placeholder='Nombre del Grupo'
            name='groupName'
            required
            value={guide?.groupName}
            onChange={onChange}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          icon
          labelPosition='left'
          size='large'
          onClick={toggle}
        >
          <Icon name='arrow circle left' /> {t('generic.goBack')}
        </Button>
        <Button
          color='green'
          icon
          labelPosition='left'
          size='large'
          onClick={() => {
            onAddGroupPage()
            toggle()
          }}
        >
          <Icon name='save' /> {t('generic.save')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
