import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Form, Icon } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import { associateEntity } from '../Service/GameEntitiesService'
import { downloadToCsv } from '../Helpers/downloadCsv'

export default function AdminAssociateEntity () {
  const { t } = useTranslation()

  const ENTITIES = [
    { key: 'esrb', value: 'esrb', text: 'Esrb' },
    { key: 'genres', value: 'genres', text: 'Géneros' },
    { key: 'pegiAge', value: 'pegiAge', text: 'Edad PEGI' },
    { key: 'pegies', value: 'pegies', text: 'Categoría PEGI' },
    { key: 'serie', value: 'serie', text: 'Sagas' },
    { key: 'subgenres', value: 'subgenres', text: 'Subgéneros' }
  ]

  const [results, setResults] = useState([])
  const [form, setForm] = useState({
    entity: '',
    oldId: '',
    newId: ''
  })

  const onChange = (e, data) => {
    const formUpdated = {
      ...form,
      [data.name]: data.value
    }

    setForm(formUpdated)
  }

  const onSave = async () => {
    const { data } = await associateEntity(form)
    setResults(data)
    toast.info(`Operación realizada con éxito. Se han desasociado un total de ${data?.length} juegos.`)
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='admin-title'>
          <span>{ t('admin.associateEntity') }</span>
        </div>
        <div>
          <Button
            color='green'
            icon
            labelPosition='left'
            type='submit'
            onClick={onSave}
          >
            <Icon name='save' /> {t('generic.save')}
          </Button>
          {
            results?.length
              ? <Button
                icon
                labelPosition='left'
                type='button'
                primary
                onClick={() => downloadToCsv(results)}
              >
                <Icon name='download' /> {t('generic.download')}
              </Button>
              : null
          }
        </div>
      </div>
      <Form>
        <Form.Group widths='equal'>
          <Form.Select
            label='Entidad a Modificar'
            name='entity'
            options={ENTITIES}
            required
            value={form.entity}
            onChange={onChange}
          />
          <Form.Input
            label='Id. Entidad a borrar'
            name='oldId'
            required
            type='number'
            value={form.id}
            onChange={onChange}
          />
          <Form.Input
            label='Id. Entidad a añadir'
            name='newId'
            required
            type='number'
            value={form.id}
            onChange={onChange}
          />
        </Form.Group>
      </Form>
    </>
  )
}
