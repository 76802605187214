import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadEsrbById,
  loadEsrbList,
  removeEsrb,
  saveEsrb,
  setEsrbData,
  setEsrbList,
  updateState
} from '../Slice/EsrbSlice'
import {
  obtainEsrbByFilter,
  obtainEsrbById,
  createEsrb,
  updateEsrb,
  deleteEsrb
} from '../Service/EsrbService'

function * fetchEsrbList () {
  try {
    const { filter } = yield select((state) => state.esrb)
    const data = yield call(obtainEsrbByFilter, filter)
    yield put(setEsrbList(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchEsrbById () {
  try {
    const { keyId } = yield select((state) => state.esrb)
    const data = yield call(obtainEsrbById, keyId)
    yield put(setEsrbData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storeEsrb () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.esrb)
    if (isValid) {
      if (keyId > 0) {
        yield call(updateEsrb, keyId, form)
      } else {
        yield call(createEsrb, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * eraseErsb (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const esrbId = action.payload
    yield call(deleteEsrb, esrbId)

    // reload list
    const filter = yield select((state) => state.esrb.filter)
    const data = yield call(obtainEsrbByFilter, filter)
    yield put(setEsrbList(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * esrbSaga () {
  yield takeEvery(loadEsrbList, fetchEsrbList)
  yield takeEvery(loadEsrbById, fetchEsrbById)
  yield takeEvery(saveEsrb, storeEsrb)
  yield takeEvery(removeEsrb, eraseErsb)
}

export default esrbSaga
