import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Icon, Modal } from 'semantic-ui-react'

import '../css/GuideForm.css'

import { resetGuidePages, setPageName, setPageGroupAssigned, setPageUrl, setPages } from '../../Slice/GuideSlice'

import SearchGuideGroups from '../../Views/SearchGuideGroups'

export default function AddGuidePageModal ({ guide, infoToEdit, open, onChange, toggle }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(setPageName(infoToEdit?.title))
    dispatch(setPageUrl(infoToEdit?.url))

    if (open) {
      if (!infoToEdit?.pageGroupAssigned) {
        dispatch(setPageGroupAssigned(''))
        return
      }

      const onEditedPage = guide?.pages.find((page) => page.title === infoToEdit.pageGroupAssigned)
      dispatch(setPageGroupAssigned(onEditedPage?.title))
    }
  }, [infoToEdit])

  const onAddPage = () => {
    const pagesMapped = []

    if (!guide.pageGroupAssigned || guide?.pageGroupAssigned?.length === 0) {
      const newPages = [...guide?.pages, {
        type: 'page',
        title: guide?.pageName,
        isPublished: 0,
        path: '',
        url: guide?.pageUrl,
        id: guide?.pages.length + 1
      }]

      pagesMapped.push(newPages)
      dispatch(setPages(pagesMapped.flat()))
    } else {
      const groupFound = guide?.pages.find((page) => page.title === guide.pageGroupAssigned)
      const pagesFiltered = guide?.pages.filter((page) => page.title !== groupFound.title)

      const newPages = [
        ...pagesFiltered,
        {
          type: 'group',
          title: groupFound.title,
          id: groupFound.id,
          pages: [
            ...groupFound.pages,
            {
              type: 'page',
              title: guide?.pageName,
              isPublished: 0,
              path: '',
              url: guide?.pageUrl,
              pageGroupAssigned: guide?.pageGroupAssigned,
              id: groupFound.pages.length + 1
            }
          ]
        }
      ]

      dispatch(setPages(newPages))
      dispatch(resetGuidePages(true))
    }
  }

  const onEditPage = () => {
    if (infoToEdit.type === 'page' && !infoToEdit.pageGroupAssigned) {
      const pagesFiltered = guide?.pages.filter((page) => infoToEdit.title !== page.title)
      const newPages = [
        ...pagesFiltered,
        {
          type: 'page',
          title: guide?.pageName,
          isPublished: 0,
          path: '',
          url: guide?.pageUrl,
          pageGroupAssigned: guide?.pageGroupAssigned,
          id: infoToEdit.id
        }
      ]

      const pagesSorted = newPages.sort((a, b) => a.id - b.id)
      dispatch(setPages(pagesSorted))
    } else {
      const groupFound = guide?.pages.find((page) => page.title === infoToEdit.pageGroupAssigned)
      const groupPagesFiltered = groupFound?.pages?.filter((page) => page.title !== infoToEdit.title)
      const pagesFiltered = guide?.pages.filter((page) => page.title !== groupFound.title)

      if (infoToEdit.pageGroupAssigned === guide.pageGroupAssigned) {
        const newPages = [
          ...pagesFiltered,
          {
            type: 'group',
            title: groupFound.title,
            id: groupFound.id,
            pages: [
              ...groupPagesFiltered,
              {
                type: 'page',
                title: guide?.pageName,
                isPublished: 0,
                path: '',
                url: guide?.pageUrl,
                pageGroupAssigned: guide?.pageGroupAssigned,
                id: infoToEdit.id
              }
            ]
          }
        ]

        const pagesSorted = newPages.sort((a, b) => a.id - b.id)
        dispatch(setPages(pagesSorted))
      } else {
        const groupToAddPage = guide?.pages.find((page) => page.title === guide.pageGroupAssigned)
        const pagesMapped = guide?.pages.map((page) => {
          if (page.type === 'page') {
            return page
          } else {
            if (page.title !== groupToAddPage.title) {
              const newPage = {
                ...page,
                pages: groupPagesFiltered
              }

              return newPage
            } else {
              const newPage = {
                ...page,
                pages: [
                  ...groupToAddPage.pages,
                  {
                    type: 'page',
                    title: guide?.pageName,
                    isPublished: 0,
                    path: '',
                    url: guide?.pageUrl,
                    pageGroupAssigned: guide?.pageGroupAssigned,
                    id: groupToAddPage.pages.length + 1
                  }
                ]
              }

              return newPage
            }
          }
        })

        const pagesSorted = pagesMapped.sort((a, b) => a.id - b.id)
        dispatch(setPages(pagesSorted))
      }
    }

    dispatch(resetGuidePages(true))
  }

  return (
    <Modal open={open}>
      <Modal.Header className='bg-info'>
        { infoToEdit ? <p>Editar Página</p> : <p>Nueva Página</p> }
      </Modal.Header>
      <Modal.Content>
        <div style={{ alignItems: 'center', display: 'flex', marginBottom: '1rem' }}>
          <p style={{ margin: 0, width: '10%' }}>Grupo</p>
          <SearchGuideGroups
            name='pageGroupAssigned'
            list={guide?.pages}
            value={guide?.pageGroupAssigned ? guide?.pageGroupAssigned : infoToEdit?.pageGroupAssigned}
            onChange={onChange}
          />
        </div>
        <div style={{ alignItems: 'center', display: 'flex', marginBottom: '1rem' }}>
          <p style={{ margin: 0, width: '10%' }}>Nombre</p>
          <Form.Input
            className='guide-input'
            placeholder='Nombre de la Página'
            name='pageName'
            required
            value={guide?.pageName}
            onChange={onChange}
          />
        </div>
        <div style={{ alignItems: 'center', display: 'flex', marginBottom: '1rem' }}>
          <p style={{ margin: 0, width: '10%' }}>URL</p>
          <Form.Input
            placeholder='URL de la Página'
            name='pageUrl'
            required
            value={guide?.pageUrl}
            onChange={onChange}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          icon
          labelPosition='left'
          size='large'
          onClick={() => {
            toggle()
            dispatch(resetGuidePages(true))
          }}
        >
          <Icon name='arrow circle left' /> {t('generic.goBack')}
        </Button>
        { infoToEdit === null
          ? <Button
              color='green'
              icon
              labelPosition='left'
              size='large'
              onClick={() => {
                onAddPage()
                toggle()
              }}
            >
              <Icon name='edit' /> {t('generic.save')}
            </Button>
          : <Button
              color='green'
              icon
              labelPosition='left'
              size='large'
              onClick={() => {
                onEditPage()
                toggle()
              }}
            >
              <Icon name='save' /> {t('generic.edit')}
            </Button>
        }
      </Modal.Actions>
    </Modal>
  )
}
