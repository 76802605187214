import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import { loginAzure } from './Services/Azure/AzureService'
import { setToken } from './Features/User/Slice/UserSlice'
import { Interceptor } from './Services/Interceptor'
import Router from './Layout/Router'
import './App.css'

function App () {
  const { instance } = useMsal()
  const dispatch = useDispatch()

  const login = async () => {
    const token = await loginAzure(instance)
    localStorage.setItem('user', token)
    dispatch(setToken(token))
  }

  useEffect(() => {
    if (window.location.pathname !== '/unauthorized') {
      login().then()
    }
  }, [])

  return (
    <Interceptor>
      <Router />
    </Interceptor>
  )
}

export default App
