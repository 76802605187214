import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'

import { loadCompanies } from '../Slice/CompanySlice'

export default function SearchCompany ({ label = 'Compañía', name = 'company', value, onChange, size = 7 }) {
  const dispatch = useDispatch()
  const { isLoading, list } = useSelector(state => state.company)
  const [selectedValue, setSelectedValue] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(loadCompanies({ limit: 15000 }))
  }, [])

  const onDropdownChange = (e, data) => {
    if (data.value) {
      if (typeof onChange === 'function') {
        onChange(e, data)
        setSelectedValue(data.value)
      }
    }
  }

  return (
    <Form.Dropdown
      fluid
      icon='search'
      label={t(`company.${label}`)}
      loading={isLoading}
      name={name}
      options={list.length > 0 ? list.map((element) => ({
        key: element.id,
        text: element.name,
        value: element.id,
        description: element.name
      })) : []}
      placeholder={t('company.search')}
      search
      selection
      value={selectedValue === null ? value : selectedValue}
      width={size}
      onChange={onDropdownChange}
    />
  )
}
