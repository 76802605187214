import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Form, Icon } from 'semantic-ui-react'

import { desassociateEntity } from '../Service/GameEntitiesService'
import { toast } from 'react-toastify'
import { downloadToCsv } from '../Helpers/downloadCsv'

const ENTITIES = [
  { key: 'serie', value: 'serie', text: 'Sagas' },
  { key: 'genres', value: 'genres', text: 'Géneros' },
  { key: 'subgenres', value: 'subgenres', text: 'Subgéneros' }
]

export default function AdminDesassociateEntity () {
  const { t } = useTranslation()

  const [results, setResults] = useState([])
  const [form, setForm] = useState({
    entity: '',
    id: ''
  })

  const onChange = (e, data) => {
    const formUpdated = {
      ...form,
      [data.name]: data.value
    }

    setForm(formUpdated)
  }

  const onSave = async () => {
    const { data } = await desassociateEntity(form)
    setResults(data)
    toast.info(`Operación realizada con éxito. Se han desasociado un total de ${data?.length} juegos.`)
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='admin-title'>
          <span>{ t('admin.desassociateEntity') }</span>
        </div>
        <div>
          <Button
            color='green'
            icon
            labelPosition='left'
            type='submit'
            onClick={onSave}
          >
            <Icon name='save' /> {t('generic.save')}
          </Button>
          {
            results?.length
              ? <Button
                icon
                labelPosition='left'
                type='button'
                primary
                onClick={() => downloadToCsv(results)}
              >
                <Icon name='download' /> {t('generic.download')}
              </Button>
              : null
          }
        </div>
      </div>
      <Form>
        <Form.Group widths='equal'>
          <Form.Select
            label='Entidad a Borrar'
            name='entity'
            options={ENTITIES}
            required
            value={form.entity}
            onChange={onChange}
          />
          <Form.Input
            label='Identificador de la Entidad'
            name='id'
            required
            type='number'
            value={form.id}
            onChange={onChange}
          />
        </Form.Group>
      </Form>
    </>
  )
}
