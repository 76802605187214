import './css/AdminPage.css'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BiCog } from 'react-icons/bi'
import { Button, Container, Divider, Icon } from 'semantic-ui-react'

import AdminAssociateEntity from '../Views/AdminAssociateEntity'
import AdminDesassociateEntity from '../Views/AdminDesassociateEntity'
import AdminTechnicalSpecifications from '../Views/AdminTechnicalSpecifications'

const ADMIN_ACTIONS = {
  associate_entity: 'Asociar Entidad',
  desassociate_entity: 'Desasociar Entidad a Juegos',
  technical_specifications: 'Especificaciones Técnicas'
}

export default function AdminPage () {
  const { t } = useTranslation()

  const [selectedOption, setSelectedOption] = useState(null)

  return (
    <Container className='admin-page'>
      <div style={{ display: 'flex' }}>
        <Icon size='large'><BiCog /></Icon>
        <span>{ t('menu.adminPanel') }</span>
      </div>
      <Divider />
      <h2>Bienvenido al Panel de Administración de Gamepedia. Selecciona la operación que quieres realizar.</h2>
      <div style={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
        <Button primary onClick={() => setSelectedOption(ADMIN_ACTIONS.associate_entity)}>
          { t('admin.associateEntity') }
        </Button>
        <Button primary onClick={() => setSelectedOption(ADMIN_ACTIONS.desassociate_entity)}>
          { t('admin.desassociateEntity') }
        </Button>
        <Button primary onClick={() => setSelectedOption(ADMIN_ACTIONS.technical_specifications)}>
          {t('admin.technicalSpecifications')}
        </Button>
      </div>
      {
        selectedOption === ADMIN_ACTIONS.technical_specifications && <AdminTechnicalSpecifications />
      }
      {
        selectedOption === ADMIN_ACTIONS.desassociate_entity && <AdminDesassociateEntity />
      }
      {
        selectedOption === ADMIN_ACTIONS.associate_entity && <AdminAssociateEntity />
      }
    </Container>
  )
}
