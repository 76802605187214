import { select, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadSeries,
  loadSerieById,
  setSeries,
  setSerieData,
  saveSerie,
  removeSerie,
  updateState
} from '../Slice/SerieSlice'
import {
  obtainSerieByFilter,
  obtainSerieById,
  createSerie,
  updateSerie,
  deleteSerie
} from '../Service/SerieService'

function * fetchSeries () {
  try {
    const { filter } = yield select(state => state.serie)
    const data = yield call(obtainSerieByFilter, filter)
    yield put(setSeries(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * fetchSerieById () {
  try {
    const { keyId } = yield select((state) => state.serie)
    const data = yield call(obtainSerieById, keyId)
    yield put(setSerieData({ data }))
  } catch (e) {
    const error = e.code === 404 ? e.code : e.message
    yield put(updateState({ error }))
  }
}

function * storeSerie () {
  try {
    const { isValid, keyId, form } = yield select((state) => state.serie)
    if (isValid) {
      if (keyId > 0) {
        yield call(updateSerie, keyId, form)
      } else {
        yield call(createSerie, form)
      }
    }
    yield put(updateState({ success: isValid }))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * eraseSerie (action) {
  try {
    const isAdmin = yield select((state) => state.user)
    if (!isAdmin) {
      return
    }

    const keyId = action.payload
    yield call(deleteSerie, keyId)

    // reload list
    const { filter } = yield select(state => state.serie)
    const data = yield call(obtainSerieByFilter, filter)
    yield put(setSeries(data))
  } catch (e) {
    yield put(updateState({ error: e.message }))
  }
}

function * serieSaga () {
  yield takeEvery(loadSeries, fetchSeries)
  yield takeEvery(loadSerieById, fetchSerieById)
  yield takeEvery(saveSerie, storeSerie)
  yield takeEvery(removeSerie, eraseSerie)
}

export default serieSaga
