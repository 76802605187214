import React from 'react'
import ReactDOM from 'react-dom/client'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { Provider } from 'react-redux'
import store from './Store/store'
import './Translations/i18n'
// Styles
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
// Components
import App from './App'

// Date picker
import 'react-datepicker/dist/react-datepicker.css'

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT}`,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
  </MsalProvider>
)
