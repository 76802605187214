import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Container, Icon } from 'semantic-ui-react'
import { GiGamepad } from 'react-icons/gi'
import ListTable from '../../Common/Views/ListTable'
import DeleteConfirmModal from '../../Common/Views/DeleteConfirmModal'
import './css/GameListPage.css'

import { loadGames, newGame, removeGame, resetGame, updateState } from '../Slice/GameSlice'
import { newGamePlatform } from '../../GamePlatform/Slice/GamePlatformSlice'

export default function GameListPage () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    list,
    filter,
    totalPages,
    isLoading,
    isError,
    message
  } = useSelector((state) => state.game)
  const [deleteGame, setDeleteGame] = useState({})

  const columns = [
    {
      name: 'id',
      title: 'generic.id',
      filter: false,
      props: {
        textAlign: 'right',
        width: 1
      }
    },
    {
      name: 'name',
      title: 'generic.name'
    },
    {
      name: 'alternativeName',
      title: 'game.otherName'
    },
    {
      name: 'website',
      title: 'generic.website'
    }
  ]

  useEffect(() => {
    onReload(filter)
    onResetGame()
  }, [])

  const onReload = (payload) => {
    dispatch(loadGames(payload))
  }

  const onResetGame = () => {
    dispatch(resetGame(true))
  }

  useEffect(() => {
    if (isError && message) {
      toast.error(message)
      dispatch(updateState())
    }
  }, [isError, message])

  const handleNewGame = () => {
    dispatch(newGame())
    dispatch(newGamePlatform())
    navigate('/game/new')
  }

  const onEdit = (gameId) => {
    navigate(`/game/${gameId}`)
  }

  const onDelete = (game) => {
    setDeleteGame(game)
  }

  const handleDeleteGame = () => {
    dispatch(removeGame(deleteGame.id))
    setDeleteGame({})
  }

  return (
    <Container className='game-list-page'>
      <div className='game-title'>
        <Icon size='large'><GiGamepad /></Icon>
        <span>{ t('menu.games') }</span>
        <Button
          floated='right'
          icon
          labelPosition='left'
          primary
          size='large'
          onClick={handleNewGame}>
          <Icon name='gamepad' /> { t('game.new') }
        </Button>
      </div>
      <ListTable
        color='blue'
        isLoading={isLoading}
        columns={columns}
        filter={filter}
        data={list}
        totalPages={totalPages}
        onReload={onReload}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      <>
      <DeleteConfirmModal open={ !!deleteGame.id }
        title={ t('game.deleteTitle') }
        message={ t('game.deleteQuestion') }
        item={ deleteGame.name }
        onCancel={ () => setDeleteGame({}) }
        onDelete={ handleDeleteGame }
      />
      </>
    </Container>
  )
}
