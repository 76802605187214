import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, Form, Modal } from 'semantic-ui-react'

export default function DuplicatePlatformModal ({ open, platform, size = 12, onDuplicate, onCancel, setPlatform }) {
  const { t } = useTranslation()

  const { form: game } = useSelector((state) => state.game)
  const { list } = useSelector((state) => state.platform)

  const platforms = game.platforms.map(platform => {
    const platformFound = list.find(plat => plat.id === platform.platformId)
    return {
      ...platform,
      name: platformFound?.name
    }
  })

  const onHandle = (fn) => {
    return () => {
      if (typeof fn === 'function') {
        fn()
      }
    }
  }

  const onDropdownChange = (e, data) => {
    setPlatform(data.value)
  }

  return (
    <Modal open={open}>
      <Modal.Header className='bg-info'>
        <span className='ms-3'>
          {t('platform.duplicate')}
        </span>
      </Modal.Header>
      <Modal.Content>
        <span>{t('gamePlatform.duplicatePlatform')}.</span>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form autoComplete='off'>
            <div style={{ border: '1px solid gray', marginTop: '1rem', padding: '.5rem' }}>
              <Form.Dropdown
                fluid
                options={platforms.map((platform) => ({
                  key: platform.platformId,
                  text: platform.name,
                  value: platform.platformId,
                  description: platform.name
                }))}
                placeholder={t('platform.search')}
                search
                onChange={onDropdownChange}
              />
            </div>
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          labelPosition='left'
          icon='arrow alternate circle left'
          content={ t('gamePlatform.noDuplicatePlatform') }
          onClick={onHandle(onCancel)}
        />
        <Button
          color='green'
          disabled={!platform}
          labelPosition='left'
          icon='trash'
          content={t('generic.duplicate')}
          onClick={onHandle(onDuplicate)}
        />
      </Modal.Actions>
    </Modal>
  )
}
