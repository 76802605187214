import { buildParams, CoreAPI } from '../../../Services/CoreAPI'

export const obtainSubgenreByFilter = async (filter) => {
  let endpoint = '/v1/subgenre'

  if (filter) {
    const params = buildParams(filter)
    endpoint = `/v1/subgenre?${params.toString()}`
  }

  const resp = await CoreAPI.get(endpoint)

  const {
    data: list = [],
    pagination: { totalPages = 1 } = {}
  } = resp

  if (list?.data) {
    return { list: list.data, totalPages }
  } else {
    return { list, totalPages }
  }
}

export const obtainSubgenreById = async (subgenreId) => {
  const endpoint = `/v1/subgenre/${subgenreId}`
  const resp = await CoreAPI.get(endpoint)

  if (resp.data?.data) {
    return resp.data.data
  }

  return resp.data
}

export const createSubgenre = async (data) => {
  const endpoint = '/v1/subgenre'
  const resp = await CoreAPI.post(endpoint, data)
  return resp.data
}

export const updateSubgenre = async (subgenreId, data) => {
  const endpoint = `/v1/subgenre/${subgenreId}`
  const resp = await CoreAPI.put(endpoint, data)
  return resp.data
}

export const deleteSubgenre = async (subgenreId) => {
  const endpoint = `/v1/subgenre/${subgenreId}`
  const resp = await CoreAPI.delete(endpoint)
  return resp.data
}
